import { IN_HOUSE_URL } from "../../../../../_consts";
import { numberWithCommas } from "../../../../_helper";
import { ArrowRightSmall } from "../../../organisms/Mypage/Icons/Icons";
import * as S from "./Coupon.style";

export const CouponBPOnly = ({ couponData }) => {
  return (
    <S.CouponWrapper>
      <S.Coupon>
        <S.CouponName>{couponData.title}</S.CouponName>
        <S.CouponBenefit>
          {numberWithCommas(couponData.benefit_detail)}
          {couponData.benefit_type === "percent" ? "% 할인" : ""}
          {couponData.benefit_type === "target" ? "원 구매" : ""}
          {couponData.benefit_type === "amount" ? "원 할인" : ""}
        </S.CouponBenefit>
        <S.CouponMinimumCondition>
          -{" "}
          {couponData.minimum_condition > 0
            ? `${numberWithCommas(couponData.minimum_condition)}` +
              `원 이상 결제시`
            : "구매금액 제한 없음"}
        </S.CouponMinimumCondition>
        <S.CouponExpired>
          - {couponData.expired_at}까지 <b>D-{couponData.due}</b>
        </S.CouponExpired>
      </S.Coupon>
      <S.CouponCtaContainer>
        <S.Cta
          onClick={() => {
            window.location = couponData.course_url ?? IN_HOUSE_URL.수업탐색;
          }}
        >
          쿠폰 사용하기 <ArrowRightSmall color={"#141617"} />
        </S.Cta>
      </S.CouponCtaContainer>
    </S.CouponWrapper>
  );
};
