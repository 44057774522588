import { IN_HOUSE_URL } from "../../../../_consts";
import { logout } from "../../../../_helpers";

export const sccAnchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}`,
  brand: "온라인",
};

const catalogAnchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: IN_HOUSE_URL.상품탐색,
  brand: "온라인",
};

const catalogMobileAnchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: IN_HOUSE_URL.상품탐색,
  brand: "온라인",
};

const kdtAnchor = {
  key: "kdt",
  name: "부트캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "온라인",
  tag: "국비",
};

const intellipickAnchor = {
  key: "intellipick",
  name: "개발자 채용 공고",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
};

const intellipickMobileAnchor = {
  key: "intellipick",
  name: "인텔리픽",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
  mobileText: "신입 개발자 채용 공고를 한 곳에서",
};

export const blogAnchor = {
  key: "blog",
  name: "블로그",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/blog`,
  brand: "블로그",
};

export const communityAnchor = {
  key: "community",
  name: "커뮤니티",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/community`,
  brand: "커뮤니티",
};

export const eventAnchor = {
  key: "event",
  name: "이벤트",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/event`,
  brand: "이벤트",
};

export const exhibitionAnchor = {
  key: "exhibition",
  name: "수강생 작품",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/exhibition`,
  brand: "수강생 작품",
};

export const faqAnchor = {
  key: "faq",
  name: "고객센터",
  href: "https://support.spartacodingclub.kr/",
};

export const BizAnchor = {
  key: "bisness",
  name: "기업 서비스",
  onClick: () => {},
  brand: "기업 서비스",
};

export const bizEducationAnchor = {
  key: "bizEducation",
  name: "기업 교육",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/b2b`,
  brand: "기업 교육",
  isSub: true,
};

export const bizOutsourcingAnchor = {
  key: "bizOutsourcing",
  name: "외주 개발",
  href: "https://sparta-builders.com/",
  brand: "외주 개발",
  isSub: true,
};

export const bizRecruitAnchor = {
  key: "bizRecruit",
  name: "신입 개발자 채용",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "신입 개발자 채용",
  isSub: true,
};

export const voucherAnchor = {
  key: "voucher",
  name: "수강권",
  href: `/mypage/myvoucher`,
};
export const classroomAnchor = {
  key: "classroom",
  name: "내 강의실",
  href: IN_HOUSE_URL.신_내강의실,
};

export const registrationStatusAnchor = {
  key: "registrationStatus",
  name: "국비 신청 내역",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/kdc/registration_status`,
};

export const homeworkFeedbackAnchor = {
  key: "homework",
  name: "숙제 피드백",
  href: `/homework/feedback`,
};

export const certificateAnchor = {
  key: "certificate",
  name: "수강증/수료증",
  href: `/mypage/mycertificate`,
};
export const basicInfoAnchor = {
  key: "basicInfo",
  name: "계정",
  href: `/mypage/mybasicinfo`,
};

export const pointAnchor = {
  key: "point",
  name: "포인트",
  href: `/mypage/mypoint`,
};

export const couponAnchor = {
  key: "coupon",
  name: "쿠폰",
  href: `/mypage/mycoupon`,
};

export const logoutAnchor = {
  key: "logout",
  name: "로그아웃",
  onClick: logout,
};

export const rcmdsfreeAnchor = {
  key: "rcmdsfree",
  name: "친구추천",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/rcmdsfree`,
};
export const bizDropdownList = [
  bizEducationAnchor,
  bizOutsourcingAnchor,
  bizRecruitAnchor,
];
export const topAnchors = [];

export const topRightAnchors = [faqAnchor, BizAnchor];

export const mainAnchor = [
  catalogAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileMainAnchor = [
  catalogMobileAnchor,
  kdtAnchor,
  communityAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileBottomAnchors = [intellipickMobileAnchor];

export const mobileMyPageAnchors = [
  classroomAnchor,
  registrationStatusAnchor,
  voucherAnchor,
  certificateAnchor,
  homeworkFeedbackAnchor,
  basicInfoAnchor,
];

export const catalogAllAnchor = {
  key: "catalogAll",
  name: "전체",
  href: IN_HOUSE_URL.상품탐색,
  brand: "전체",
  isSub: true,
};

export const catalogEventAnchor = {
  key: "catalogEvent",
  name: "새해에는 코딩",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=새해에는 코딩`,
  brand: "새해에는 코딩",
  isSub: true,
};

export const catalogKdcAnchor = {
  key: "catalogAll",
  name: "국비지원",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=국비지원`,
  brand: "국비지원",
  isSub: true,
};

export const catalogAiAnchor = {
  key: "catalogAi",
  name: "AI",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=AI`,
  brand: "AI",
  isSub: true,
};

export const catalogAppGameAnchor = {
  key: "catalogAppGame",
  name: "앱∙게임 개발",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=앱∙게임 개발`,
  brand: "앱∙게임 개발",
  isSub: true,
};

export const catalogDataGameAnchor = {
  key: "catalogData",
  name: "데이터",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=데이터`,
  brand: "데이터",
  isSub: true,
};

export const catalogAutomationAnchor = {
  key: "catalogAutomation",
  name: "업무 자동화",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=업무 자동화`,
  brand: "업무 자동화",
  isSub: true,
};

export const catalogNocode = {
  key: "catalogNocode",
  name: "노코드",
  href: `${process.env.REACT_APP_SCC_MAIN_URL}/catalog?selectedKeyword=노코드`,
  brand: "노코드",
  isSub: true,
};

export const catalogDropdownList = [
  catalogAllAnchor,
  catalogEventAnchor,
  catalogKdcAnchor,
  catalogAiAnchor,
  catalogAppGameAnchor,
  catalogDataGameAnchor,
  catalogAutomationAnchor,
  catalogNocode,
];
