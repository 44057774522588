import * as S from "./spinner.css";

export const Spinner = () => {
  return (
    <S.Wrapper>
      <S.SpinnerContainer>
        <S.Spinner />
      </S.SpinnerContainer>
    </S.Wrapper>
  );
};
