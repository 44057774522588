import React, { Component } from "react";
import classNames from "classnames/bind";
import styles from "./FinishPage.module.scss";
import { connect } from "react-redux";
import { userActions, mypageActions } from "../../_actions";
import { params } from "../../_helpers";
import "./FinishPage.module.scss";
import {
  CustomModal_Reimburse,
  CustomModal_ReimburseEn,
} from "../../_components/CustomModal_Reimburse";
import axios from "../../_helpers/axios";
import { certActions } from "../../_actions/certs.actions";

const cx = classNames.bind(styles);

class FinishPageEn extends Component {
  state = {
    visible: false,
    enrolled_id: params().enrolled_id,
    cert_created: false,
    cert_data: null,
  };

  componentDidMount() {
    this.createCertReq();
    // const {enrolled_id} = this.state;
    // this.props.getCertInfo(enrolled_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.cert_created && this.state.cert_created) {
      this.setModal();
    }
    if (!prevProps.certs.loaded && this.props.certs.loaded) {
      const { certs } = this.props;
      this.setState({
        cert_created: true,
        file_link: certs.data.file_link,
        cert_data: certs.data.data,
      });
    }
  }

  createCertReq = async () => {
    const { enrolled_id } = this.state;
    await axios
      .post(
        "/api/cert",
        { enrolled_id },
        {
          timeout: 3 * 60 * 1000,
          baseURL: "https://op.spartacodingclub.kr",
        }
      )
      .then((resp) => {
        this.setState({
          cert_created: true,
          file_link: resp.data.file_link,
          cert_data: resp.data.data,
        });
      })
      .catch((error) => {});
  };

  setModal = () => {
    this.setState({
      visible: true,
    });
  };

  _getImagePathTreauserBox = (isOpen) => {
    if (isOpen) {
      return ("/assets/images/lecture/box_open_effects.gif");
    } else {
      return ("/assets/images/lecture/treasure_box_unopened.png");
    }
  };

  handleModalVisible = () => {};

  render() {
    const { visible, enrolled_id, cert_data } = this.state;
    return (
      <div>
        <div
          className={cx("fireworks")}
          style={{
            "--bg-image": `url(${("/assets/images/finish/fireworks.gif")}`,
          }}
          alt=""
        >
          <div className={cx("treasure")}>
            <img src={this._getImagePathTreauserBox(true)} alt="" />
            <h2>
              Congratulations, {this.props.user.name}🎉
              <br />
              The certificate is being issued...
            </h2>
            <h4>It will take less than 2 minutes.</h4>
          </div>
        </div>
        <CustomModal_ReimburseEn
          visible={visible}
          enrolled_id={enrolled_id}
          cert_data={cert_data}
          handleModalVisible={this.handleModalVisible}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, enrolledDone, enrolledStatus, certs } = state;
  const { user } = state.authentication;
  return {
    authentication,
    user,
    enrolledDone,
    enrolledStatus,
    certs,
  };
}

const connectedFinishPageEn = connect(mapStateToProps, {
  refreshInfo: userActions.refreshInfo,
  logout: userActions.logout,
  loginWithToken: userActions.loginWithToken,
  getMypage: mypageActions.getMypage,
  getCertInfo: certActions.getCertInfo,
})(FinishPageEn);
export { connectedFinishPageEn as FinishPageEn };
