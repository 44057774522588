import privateInstance from "../../_helper/axios";
import privateInstanceV3 from "../../_helper/axiosV3";

export async function getIsNeedDailyGovAuth(round_id) {
  return await privateInstanceV3
    .get(`/auth/gov/rounds/${round_id}/daily`)
    .then((resp) => resp.data);
}

export async function getGovUserInfo() {
  return await privateInstance.get(`/gov/users/info`).then((resp) => resp.data);
}

async function getHasGovAuthorized() {
  return await privateInstance
    .get(`/gov/users/authorizeds`)
    .then((resp) => resp.data);
}

async function getGovDailyRecord(userId, startDate, endDate) {
  return await privateInstance
    .get(`/gov/camp/users/${userId}?start=${startDate}&end=${endDate}`)
    .then((resp) => resp.data);
}

async function doGovEnrolledDetail(enrolled_id, enrolled_detail_id) {
  return await privateInstance
    .post(`/gov/enrolleds/${enrolled_id}/details/${enrolled_detail_id}`)
    .then((resp) => resp.data);
}

async function doGovEnrolledHomework(enrolled_id, enrolled_homework_id) {
  return await privateInstance
    .post(`/gov/enrolleds/${enrolled_id}/homeworks/${enrolled_homework_id}`)
    .then((resp) => resp.data);
}

async function getIsGovEnrolledBlocked(enrolled_id) {
  return await privateInstance
    .get(`/gov/enrolleds/${enrolled_id}/blocked`)
    .then((resp) => resp.data);
}

async function getGovRoundStartSoon(category) {
  return await privateInstance
    .get(`/gov/rounds/soon/${category}`)
    .then((resp) => resp.data);
}

async function getGovRoundCanEnroll() {
  return await privateInstance
    .get(`/gov/rounds/can_enroll`)
    .then((resp) => resp.data);
}

async function getGovAttendRecords() {
  return await privateInstance
    .get(`/gov/attend/records`)
    .then((resp) => resp.data);
}
async function postGovAttendRecords({ rtype, reason }) {
  return await privateInstance
    .post(`/gov/attend/records`, { rtype, reason })
    .then((resp) => resp.data);
}

async function getTotalStudyTime({ user_id }) {
  const response = await privateInstance.get(`/gov/attend/study-total`, {
    user_id,
  });
  return response.data;
}

export const govService = {
  getTotalStudyTime,
  getGovRoundCanEnroll,
  getHasGovAuthorized,
  doGovEnrolledDetail,
  doGovEnrolledHomework,
  getIsGovEnrolledBlocked,
  getGovRoundStartSoon,
  getGovAttendRecords,
  postGovAttendRecords,
  getGovDailyRecord,
};
