import { useQuery } from "react-query";
import privateInstance from "../../_helper/axios";
import privateInstanceV3 from "../../_helper/axiosV3";

export const getUserMetaData = async (userId) => {
  return await privateInstance
    .get(`/users/meta?user_id=${userId}`)
    .then((resp) => new userMetaDataModel(resp.data.context));
};

export const getUserV2CouponCount = async () => {
  try {
    return await privateInstanceV3
      .get(`/user-info/available-coupons/count`)
      .then((resp) => resp.data);
  } catch (e) {
    console.log(e);
  }
};

export const useUserV2CouponCountQuery = (userId) => {
  return useQuery({
    queryKey: ["userV2CouponCount", userId],
    queryFn: getUserV2CouponCount,
  });
};

export const useUserMetaData = (userId) => {
  return useQuery({
    queryKey: ["userMetaData", userId],
    queryFn: () => getUserMetaData(userId),
  });
};

class userMetaDataModel {
  constructor(data) {
    this.classroom = !!data && data.is_enrolled_exist && "수강중";
    this.point = !!data ? `${data.point_amount.toLocaleString()}원` : undefined;
    this.coupon = !!data ? `${data?.active_coupon_count}장` : undefined;
    this.voucher = !!data ? `${data?.active_voucher_count}장` : undefined;
  }
}
