import { config } from "../config";
import { handleResponse, authHeader } from "../_helpers";
import { getApiLectureNavInfoUrl, getGETAuthOptions } from "../_urls";
import instance from "../_helpers/axios";

function getLecture(round_id, lecture_id) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };
  return fetch(
    `${config.apiUrl}/v1/rounds/${round_id}/lectures/${lecture_id}`,
    requestOptions
  ).then(handleResponse);

  // return instance.get(`/courses/${course_id}/lectures/${lecture_id}`)
  //     .then(resp => resp.data);
}

function getLectureNavInfo(round_id) {
  // alert(round_id)
  const endpoint = getApiLectureNavInfoUrl(round_id);
  const options = getGETAuthOptions();
  return fetch(endpoint, options).then(handleResponse);
}

export const lectureService = {
  getLecture,
  getLectureNavInfo,
};
