import React, {Component} from "react";
import {Card, Collapse, Input, Button, Tooltip, Modal, message, Skeleton} from "antd";
import classNames from "classnames/bind";
import {CaretRightOutlined, QuestionCircleFilled} from "@ant-design/icons";
import styles from "./HomeworkPage.module.scss";
import {LectureNav} from "../../_components/LectureNav";
import {connect} from "react-redux";
import {homeworkActions, lectureActions} from "../../_actions";
import Editor from 'react-simple-code-editor';
import {history} from "../../_helpers";
import {highlight, languages} from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-markup'
import {getLectureUrl} from "../../_urls/lectures";
import {getHomeworkUrl} from "../../_urls";
import {NPS} from "../../_components/NPS";
import {LecNav} from "../../v2/pages/LecturePage/LecNav";
import {enrolledActions} from "../../v2/_state/actions";

const {Panel} = Collapse;
const {TextArea} = Input;

const cx = classNames.bind(styles);
const languageList = {
    python: languages.python,
    css: languages.css,
    js: languages.js,
    markup: languages.markup
};

class HomeworkPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            round_id: this.props.match.params.round_id,
            homework_id: this.props.match.params.homework_id,
            details: [],
            isDone: false,
            isLoading: false,
            goToNextModalVisible: false,
            editing: false,

            isSubmitButtonPressed: false,
            isNpsShow: false,
            final_review_url: '',
            nav_selected: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {round_id, homework_id} = this.state;
        this.props.getHomeworkItem(round_id, homework_id);
        this.setState({
            isNpsShow: false,
        })
    }

    componentDidUpdate(prevProps) {
        const {round_id, homework_id} = this.state;
        if (this.props.homework.loaded && this.props.homework.data._id !== homework_id) {
            
            this.props.getHomeworkItem(round_id, homework_id);
        }
        if (
            !prevProps.homework.loaded &&
            this.props.homework.loaded
        ) {
            const {enrolled_id} = this.props.homework.data;
            this.props.setEnrolled(enrolled_id);
            this.setState({
                isLoading: false,
                final_review_url: this.props.homework.data.final_review_url,
                temp_title: this.props.homework.data.title,
            });

            if (this.props.homework.error) {
                alert(this.props.homework.error);
            } else if (this.props.homework && this.state.details.length === 0) {
                this.setState({
                    details: this.props.homework.data.details,
                    isDone: this.props.homework.isDone
                })
            }

            const {is_nps_done} = this.props.homework.nps;
            if (this.props.homework.isSubmitted) {
                if (is_nps_done === false) {
                    this.handleOk();
                } else {
                    this.setState({
                        goToNextModalVisible: true
                    })
                }
            }
        }
    }

    onEditorChange(order, code) {
        this.setState({
            editing: true,
            details: this.state.details.map(
                item => {
                    if (order === item.order) {
                        let new_item = JSON.parse(JSON.stringify(item));
                        new_item.answer = code;
                        return {...item, ...new_item}
                    } else {
                        return item
                    }
                }
            )
        })
    }

    onFinalReviewChange = (final_review_url) => {
        this.setState({final_review_url})
    };

    _showSuccessMessage = () => {
        const {point} = this.props.homework.data;
        message.success({
            content: `+${point} 포인트 적립 완료!`,
            className: 'custom-class',
            style: {
                position: 'absolute',
                top: '7px',
                right: '40px',
            },
        });
    };

    submitHomework() {
        const {round_id, homework_id, details, final_review_url} = this.state;
        for (let i = 0; i < details.length; i++) {
            if (details[i].answer === '') {
                alert(`숙제 코드를 입력해주세요!`);
                return;
            }
        }
        this.setState({
            isLoading: true,
            isSubmitButtonPressed: true,
            goToNextModalVisible: true,
        });
        this.props.postHomeworkItem(round_id, homework_id, details, final_review_url);
    }

    _setIsNpsShow = (value) => {
        this.setState({
            isNpsShow: value,
        });
    };

    handleNavStatus = (nav_selected) => {
        this.setState({
            nav_selected,
        })
    };

    handleOk = () => {
        if (this.props.homework.isSubmitted) {
            const {isDone} = this.state;
            if (isDone === false) {
                this._showSuccessMessage();
            }

            // NPS 제출 여부에 따라 분기
            const {nps} = this.props.homework;
            if (nps.is_nps_done === false) {
                this.setState({
                    isNpsShow: true,
                });
                return;
            }

            // NPS 를 이미 제출했기 때문에 다음 내용으로 이동
            this.setState({
                goToNextModalVisible: false,
                isNpsShow: false,
            });
            const {round_id} = this.state;
            const {next_id, enrolled_id, next_week_allowed, next_start_date, enrolled_detail} = this.props.homework.data;
            if (!next_id) {
                alert('드디어 완주에 성공하셨군요!!\n완강을 진심으로 축하합니다!🏅');
                history.push(`/finish?enrolled_id=${enrolled_id}`);
                return;
            }
            if (!next_week_allowed) {
                alert(`다음 주차는 ${next_start_date}부터 수강 가능합니다.`);
                return;
            }
            let chosenUrl;
            if (next_id.includes('lectures')) {
                const lecture_id = next_id.split('/')[1];
                // this.props.getLecture(course_id, lecture_id);
                // chosenUrl = getLectureUrl(round_id, lecture_id);
                chosenUrl = `/enrolleds/${enrolled_id}/edetails/${enrolled_detail._id}`;
            } else {
                chosenUrl = getHomeworkUrl(round_id, next_id);
                this.props.getHomeworkItem(round_id, next_id);
            }
            history.push(chosenUrl);
        }
    };

    handleCancel = () => {
        this.setState({
            goToNextModalVisible: false,
        })
    };

    render() {
        const round_id = this.props.match.params.round_id;
        const homework_id = this.props.match.params.homework_id;
        let data = {round_id, homework_id};

        const {isDone, isLoading, goToNextModalVisible, isSubmitButtonPressed, isNpsShow} = this.state;
        const modalText = this.props.homework.isSubmitted ? '숙제 제출이 완료되었습니다. 다음 강의로 넘어가시겠어요?' : '숙제 제출이 완료되지 않았습니다. 다음 강의로 넘어가실 수 없습니다.';
        let details;
        if (this.state.editing) {
            details = this.state.details;
        } else {
            details = this.props.homework.data.details === undefined ? [] : this.props.homework.data.details;
        }

        const {homework} = this.props;
        const defaultActiveKey = details.map((detail, idx) => {
            // return detail.answer === '' ? undefined : String(detail.order)
            return String(detail.order)
        });

        const {course_title} = homework.data;
        if (!homework) {
            return <Skeleton/>
        }

        return (
            <div>
                {!(homework.isDone === true && this.props.homework.nps.is_nps_done === false) &&
                <div>
                    <div className={cx('lectureNavWrapper')}>
                        <div className={cx('lectureNavContainer')}>
                            {/*<LectureNav*/}
                            {/*    handleNavStatus={this.handleNavStatus}*/}
                            {/*    data={data}*/}
                            {/*/>*/}
                            <LecNav title={this.state.temp_title}/>
                        </div>
                    </div>
                    {homework.data && (
                        <div className={cx('homeworkContainer')}>
                            <h3>{homework.data.week}{round_id === '6001384a006d2727411520ec' ? '일' : '주'}차 숙제
                                - {homework.data.title}</h3>
                            <div className={cx('textContainer')}>
                                {homework.data.material &&
                                <p style={{fontSize: 16}}>👉<a style={{color: 'black', textDecoration: 'underline'}}
                                                               href={homework.data.material['link']}
                                                               target="_blank">강의자료(링크)</a>로 필요한 정보를 찾아보세요.</p>
                                }
                                {homework.data.answer && homework.data.answer !== '' &&
                                (round_id === '6001384a006d2727411520ec' && homework.data.week !== 2 || round_id !== '6001384a006d2727411520ec') &&
                                <p style={{fontSize: 16}}>👉막막하다면 <a
                                    style={{color: 'black', textDecoration: 'underline'}}
                                    href={homework.data.answer} target="_blank">답안(링크)</a>을 참고해보세요.</p>
                                }
                                {isDone &&
                                <p style={{fontSize: 16}}>👉이미 제출한 숙제입니다. 지난번 제출한 숙제를 다시 수정하여 제출할 수 있습니다.</p>
                                }

                                {homework.data.pre_install &&
                                <p style={{fontSize: 16}}>📝다음 주차 수강에 필요한 필수 설치들, 꼭 해주실거죠? <a
                                    style={{color: '#e8344e', textDecoration: 'underline'}}
                                    href={homework.data.pre_install} target="_blank">설치하러가기(링크)</a></p>
                                }
                            </div>

                            {details.length > 0 &&
                            <Collapse
                                bordered={false}
                                defaultActiveKey={defaultActiveKey}
                                expandIcon={({isActive}) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0}/>
                                )}
                                className="site-collapse-custom-collapse"
                            >
                                {
                                    details.map((detail, idx) => {
                                        return (
                                            <Panel header={detail.description} key={detail.order}>
                                                <Editor
                                                    value={detail.answer}
                                                    onValueChange={code => this.onEditorChange(detail.order, code)}
                                                    highlight={code => highlight(code, languageList[detail.language])}
                                                    padding={10}
                                                    tabSize={4}
                                                />
                                            </Panel>
                                        );
                                    })}
                            </Collapse>
                            }
                            {homework.data.course && homework.data.course.is_seasonal &&
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['0']}
                                expandIcon={({isActive}) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0}/>
                                )}
                                className="site-collapse-custom-collapse"
                            >
                                <div className={cx('review-final')}>
                                    <div className={cx('header')}>
                                        (선택) <span
                                        className={cx('bold')}>SNS #올해는코딩해 이벤트!</span>
                                    </div>

                                    <div className={cx('what')}>
                                        설특집! <span
                                        className={cx('bold')}>신년운세 패키지</span> {homework.data.week === 1 ? '1일차 ' : ''}완주를
                                        진심으로 축하드립니다! (짝짝짝👏)
                                    </div>
                                    <div className={cx('how')}>
                                        👉지금 바로!
                                        <br/>
                                        열심히 코딩하는 내 모습, 직접 만든 신년운세 페이지, 보기만 해도 있어보이는 코드 화면 등을 찍어서<br/>
                                        인스타그램에 필수 해시태그와 함께 인증해주세요~!<br/>
                                        감사의 마음을 담아 추첨을 통해 100명에게 <span
                                        style={{'textDecoration': 'underline'}}>개발자 이모티콘</span>을
                                        선물로 드립니다!
                                    </div>
                                    <div className={cx('how')}>
                                        👉필수 해시태그 : <span className={cx('bold')}>#스파르타코딩클럽 #올해는코딩해 #코딩</span>
                                    </div>
                                    <div className={cx('how')}>
                                        👉이벤트 기간은 <span className={cx('bold')}>2월 21일(일) 오후 11시 59분</span>까지입니다<br/>
                                        *인스타그램 계정이 공개되어 있어야 이벤트 참여가 가능합니다.
                                    </div>
                                </div>
                            </Collapse>
                            }
                            <Button
                                className={cx('submitButton')}
                                loading={isLoading}
                                onClick={() => this.submitHomework()}>제출하기</Button>
                            <Modal
                                className={cx('modal')}
                                visible={goToNextModalVisible && isSubmitButtonPressed && this.props.homework.nps.is_nps_done}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                okText='다음강의로'
                                cancelText='진도표 가기'
                                centered
                                closable={true}
                                cancelButtonProps={{style: {display: 'none'}}}
                            >
                                <div>
                                    {modalText}
                                </div>
                            </Modal>
                        </div>
                    )}
                </div>
                }
                {(homework.isDone === true && this.props.homework.nps.is_nps_done === false) &&
                <NPS nps={this.props.homework.nps} next_id={this.props.homework.data.next_id}
                     course_title={course_title}
                     _setIsNpsShow={this._setIsNpsShow}/>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {homework} = state;
    return {
        homework,
    };
}

const connectedHomeworkPage = connect(mapStateToProps, {
    getHomeworkItem: homeworkActions.getHomeworkItem,
    postHomeworkItem: homeworkActions.postHomeworkItem,
    getLecture: lectureActions.getLecture,
    setEnrolled: enrolledActions.setEnrolled,
    getEnrolledDetails: enrolledActions.getEnrolledDetails,
})(HomeworkPage);
export {connectedHomeworkPage as HomeworkPage};
