import React from "react";
import classNames from "classnames/bind";
import styles from "./PaymentAgreementContainer.module.scss";
import { connect } from "react-redux";

const cx = classNames.bind(styles);

const PaymentAgreementContainer = () => {
  return (
    <>
      <div className={cx("agreement")}>
        <a
          href={
            "https://teamsparta.notion.site/7b1dc644460946f08bab08b794de685f?pvs=4"
          }
        >
          개인정보수집
        </a>
        ,
        <a href={"https://www.nicepay.co.kr/cs/terms/policy1.do"}>
          전자금융거래 기본약관
        </a>
        ,<br />
        <a href={"https://www.nicepay.co.kr/cs/terms/private.do"}>
          개인정보처리방침
        </a>
        에 동의합니다.
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

const connectedPaymentAgreementContainer = connect(
  mapStateToProps,
  {}
)(PaymentAgreementContainer);
export { connectedPaymentAgreementContainer as PaymentAgreementContainer };
