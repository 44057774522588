import classNames from "classnames/bind";
import styles from "./PaymentPage_v2.module.scss";
import { NavContainer } from "../../_containers";
import { Spinner } from "./PortOneValidation/Spinner";
import { useParams } from "react-router-dom";
import {
  usePortOneValidation,
  usePortOneMobileValidation,
} from "./PortOneValidation/usePortOneValidation";

const cx = classNames.bind(styles);
export const PaymentWait = () => {
  const { order_no, course_id, round_id } = useParams();
  usePortOneValidation(order_no, course_id, round_id);
  usePortOneMobileValidation(course_id, round_id);

  return (
    <div className={cx("bg")}>
      <NavContainer />
      <Spinner />
    </div>
  );
};
