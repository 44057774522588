import { Skeleton } from "antd";
import classNames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { paymentActions } from "../../_actions";
import { CourseSummary } from "../../_components/_organisms/CourseSummary";
import { BANK_CODE, NEED_DELIVERY_COURSE_KEYWORDS } from "../../_constants";
import { POINT_REIMBURSE } from "../../_consts";
import { NavContainer } from "../../_containers";
import { history, params } from "../../_helpers";
import axios from "../../_helpers/axios";
import {
  shouldNotShowDiscountSection,
  useGetPaymentInfoByCourseId,
} from "../../_services";
import {
  getGETAuthOptions,
  getGETOptions,
  getStartPaymentUrl,
  getZeropayUrl,
} from "../../_urls";
import { config } from "../../config";
import useTimerRefVer2 from "../../hooks/useTimerVer2";
import { getCookie } from "../../v2/_helper";
import privateInstance from "../../v2/_helper/axios";
import { paymentActionsV2 } from "../../v2/_state/actions/payment.action";
import { getDeviceId } from "../../v2/businesslogics/logging";
import { OnlyDesktop, OnlyMobile } from "../../v2/components/atoms/Only/Only";
import { KdcPaymentWarn } from "../../v2/components/molecules/Payment/KdcPaymentWarn/KdcPaymentWarn";
import { SearchPost } from "../../v2/components/molecules/SearchPost";
import { Ga4Modal } from "../../v2/components/organisms/Modal/Ga4Modal/Ga4Modal";
import { KdcModal } from "../../v2/components/organisms/Modal/KdcModal/KdcModal";
import { Modal } from "../../v2/components/organisms/Modal/Modal";
import { PaymentDeliveryCheckModal } from "../../v2/components/organisms/PaymentDeliveryCheckModal";
import { PhoneVerification } from "../../v2/components/organisms/PhoneVerification";
import { initIMP } from "../../v2/lib/iamport/init";
import {
  blockingCourseIds,
  earlybirdExcludedCourses,
} from "../../v2/utils/blockingCourseIds";
import { getVbankDueDate } from "../../v2/utils/date";
import {
  CheckBeforePaymentDesktop,
  CheckBeforePaymentMobile,
} from "./CheckBeforePayment/CheckBeforePayment";
import CreditQuizLanding from "./CreditQuizLanding/CreditQuizLanding";
import { FriendsBanner } from "./FriendsBanner";
import { KdcFinalPrice } from "./KdcFinalPrice/KdcFinalPrice";
import { logCheckout, logPaymentPageview, logPurchaseUTM } from "./logging";
import { useCourseStatusApi } from "./logics";
import { PackageDetail } from "./PackageDetail/PackageDetail";
import { PaymentAgreementContainer } from "./PaymentAgreementContainer";
import { PaymentDeliveryInformation } from "./PaymentDeliveryInformation";
import { PaymentDiscountSection } from "./PaymentDiscoutSection";
import { PaymentNoticeContainer } from "./PaymentNoticeContainer";
import "./PaymentPage_v2.antdesign.custom.css";
import styles from "./PaymentPage_v2.module.scss";
import { PaymentPayMethodContainer } from "./PaymentPayMethodContainer";
import { PaymentReviewContainer } from "./PaymentReviewContainer";
import { isValidObjectId, numberWithCommas } from "./utils";

const cx = classNames.bind(styles);

function PaymentPage_v2({
  match,
  user,
  paymentInfoByCourseId,
  getPaymentInfoByCourseId,
  paymentOption,
  location,
  paymentReinforced,
  getPaymentReinforced,
  getPaymentOption,
}) {
  const { course_id, round_id } = match.params;

  const [args, setArgs] = useState(null);
  const [isForeign, setIsForeign] = useState(false);
  const [isPaypal, setIsPaypal] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isReinforced, setIsReinforced] = useState(false);
  const [method, setMethod] = useState("card");
  const [finalPrice, setFinalPrice] = useState(0);
  const [currency, setCurrency] = useState("KRW");
  const [currencyUnit, setCurrencyUnit] = useState("원");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountCoupon, setDiscountCoupon] = useState(0);
  const [couponName, setCouponName] = useState("선택 안함");
  const [couponType, setCouponType] = useState(null);
  const [discountPoint, setDiscountPoint] = useState(0);
  const [coupon_id, setCouponId] = useState("");
  const [isFriendReco, setIsFriendReco] = useState(false);
  const [friendId, setFriendId] = useState("");
  const [discountFriend, setDiscountFriend] = useState(0);
  const [isShowUserProfile, setIsShowUserProfile] = useState(false);
  const [isShowUserTooltip, setIsShowUserTooltip] = useState(false);
  const [isShowCouponOption, setIsShowCouponOption] = useState(false);
  const [isShowInstallment, setIsShowInstallment] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileShowFixedCta, setIsMobileShowFixedCta] = useState(false);
  const [isShowOptionSection, setIsShowOptionSection] = useState(true);
  const [mainCtaText, setMainCtaText] = useState("결제하기");
  const [isFullDiscount, setIsFullDiscount] = useState(false);
  const [isB2B, setIsB2B] = useState(false);
  const [discountEvent, setDiscountEvent] = useState(0);
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const [couponExpiredAt, setCouponExpiredAt] = useState("");
  const [isPhoneVerificationRequired, setIsPhoneVerificationRequired] =
    useState(false);
  const [showCouponAndPointSection, setShowCouponAndPointSection] =
    useState(false); // 쿠폰과 포인트 섹션 노출 여부
  const [showCouponSection, setShowCouponSection] = useState(true); // 쿠폰 섹션 노출 여부
  const [showPointSection, setShowPointSection] = useState(true); // 포인트 섹션 노출 여부
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isSelectedChoiceDefault, setIsSelectedChoiceDefault] = useState(true); // 선택한 옵션이 메인코스(8주)인지
  const [isShowGa4Modal, setIsShowGa4Modal] = useState(
    ["64671aa77509c0fec824b08f", "649e631c89ddea656ee54fa0"].includes(course_id)
  );

  const isDev = process.env.REACT_APP_IS_DEV == "true";
  // 주소 검색
  const [isOpenPost, setIsOpenPost] = useState(false);
  // 배송 정보
  const [deliveryChecked, setDeliveryChecked] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({
    name: user?.name,
    phone0: user?.phone.slice(0, 3),
    phone1: user?.phone.slice(3, 7),
    phone2: user?.phone.slice(7, 11),
    postCode: "",
    address: "",
    detailAddress: "",
    memo: "",
  });

  const timerRef = useRef();
  const payContext = paymentInfoByCourseId.loaded
    ? paymentInfoByCourseId.data.context
    : {};
  // 판매 종료 및 리뉴얼
  useCourseStatusApi(course_id);
  // 결제 상품 변경에 따른 고객 고지.
  useEffect(() => {
    if (course_id === "668cc24f561f34dc16f8635d") {
      alert(
        `본 상품은 격파르타 환급 챌린지 대상과목입니다.\n일반 결제 후 환급 조건에 따라 환급이 가능한 격파르타 상품으로만 구매가 가능합니다.`
      );
    }
  }, [course_id]);

  const isGov = payContext?.course?.is_gov;
  const [isKdcModalShow, setIsKdcModalShow] = useState();

  const { data: paymentInfo } = useGetPaymentInfoByCourseId(course_id);

  useEffect(() => {
    if (isGov) {
      setIsKdcModalShow(true);
    }
  }, [isGov]);

  useEffect(() => {
    const f_uid = getCookie("f_uid");
    setFriendId(f_uid);

    window.addEventListener("pageshow", function (event) {
      const historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        window.location.reload();
      }
    });
  });

  useEffect(() => {
    if (!paymentInfoByCourseId.loaded) {
      return;
    }

    // 얼리버드 남은시간 안내
    if (timerRef.current && payContext?.price?.earlybird_criteria === "time") {
      timerRef.current.classList.remove(cx("--hidden"));
    }

    // 무료 강의의 경우 바로 결제되도록 수정
    if (payContext.course.is_skip_payment && !payContext.course.is_registered) {
      startPayment();
    }

    // 연장권 적용 가능 여부
    if (paymentInfoByCourseId.data.context.course.keyword === "extend") {
      const { enrolled_id } = params();
      if (!isValidObjectId(enrolled_id)) {
        alert(
          "연장권 적용 대상강의가 확인되지 않습니다.\n고객센터로 문의해주세요"
        );
        window.location.href = `/classroom`;
      }
    }

    // 목금토일 타이머 옵션
    const isInTHUtoSUN = new Date().getDay() >= 4 || new Date().getDay() === 0;
    if (timerRef.current && isInTHUtoSUN) {
      timerRef.current.classList.remove(cx("--hidden"));
      setInterval(setTimer, 1000);
    }
  }, [paymentInfoByCourseId.loaded]);

  useEffect(() => {
    if (!paymentOption.loaded) return;

    for (const option of paymentOption.data) {
      for (const choice of option.choices) {
        if (choice.is_default) {
          setSelectedChoices([choice._id, ...selectedChoices]);
        }
      }
    }
  }, [paymentOption.loaded]);

  // 결제 정보 불러오기
  useEffect(() => {
    async function checkCountry() {
      const url =
        "https://pro.ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,mobile,query&key=FVaMw6OLSQKC0LD";
      const options = getGETOptions();
      await fetch(url, options)
        .then((resp) => resp.json())
        .then((data) => {
          // forcepaypal=y 파라메터가 전달되는 경우 무조건 paypal을 보여줌
          const forcePaypal = location.search
            .toLowerCase()
            .includes("forcepaypal=y");
          if (
            forcePaypal ||
            (data.status !== "fail" && data.country !== "South Korea")
          ) {
            alert(data.country);
            window.payment_method = "paypal";
            setIsForeign(true);
            setIsPaypal(true);
            setCurrency("USD");
            setCurrencyUnit("달러");
            setMethod("paypal");
          }
        });
    }

    if (window.checkPlatform() === "mobile") {
      setIsMobile(true);
      setIsMobileShowFixedCta(false);
      document.addEventListener("scroll", toggleReciptArea);
    } else {
      document.addEventListener("scroll", positioningReceipt);
    }

    let paramsDict = {};
    let paramsString = history.location.search;
    if (paramsString.length > 0) {
      let params = paramsString.split("?")[1].split("&");
      for (const p of params) {
        let part = p.split("=");
        paramsDict[part[0]] = part[1];
      }
    }
    setArgs(paramsDict);

    if (!paymentInfoByCourseId.loaded) {
      if (user) {
        getPaymentInfoByCourseId(course_id, history.location.search);
      }
    } else if (!paymentReinforced.loaded) {
      const round_id = paramsDict ? paramsDict.round_id : "";
      logPaymentPageview(course_id, paymentInfoByCourseId, isGov);
      getPaymentReinforced(course_id, round_id);
    } else if (!paymentOption.loaded) {
      const round_id = paramsDict ? paramsDict.round_id : "";
      getPaymentOption(course_id, round_id);
    } else {
      if (paramsDict.err) {
        alert(decodeURI(paramsDict.err));
      }
      const { coupons, price, point, course } =
        paymentInfoByCourseId.data.context;

      const { earlybird_price, display_price, event_discount_price } = price;
      const { is_registered, is_b2b, is_gov_trial } = course;

      const sumOfDefaultOptionPrice = getSumOfDefaultChoicePrice();

      if (
        paymentInfo?.context?.course?.is_paid &&
        paymentInfo?.context?.course?.is_gov
      ) {
        alert("이미 결제하신 강의입니다.\n신청 현황 페이지로 이동합니다.");
        window.location.href = `${process.env.REACT_APP_SCC_URL}/kdc/registration_status`;
        return;
      }
      if (is_registered) {
        alert("이미 등록하신 강의입니다.\n내 강의실로 이동합니다.");
        history.push("/classroom");
        return;
      }
      // 결제커스텀 적용
      const {
        is_under,
        price: price_target,
        pur_id,
        is_phone_verifictaion_needed,
      } = paymentReinforced.data;
      if (is_under) {
        setIsReinforced(is_under);
        setIsFriendReco(false);
        setFriendId("");
        setDiscountFriend(0);
        setDiscountEvent(null);
        setFinalPrice(price_target);

        setShowCouponSection(false);
        setShowPointSection(false);
        setShowCouponAndPointSection(false);
        setIsPhoneVerificationRequired(!!is_phone_verifictaion_needed); // undefined or true 로 받으므로 이중 부정 처리
        return;
      }

      setIsB2B(is_b2b);

      // 친구추천
      const f_uid = getCookie("f_uid");
      const isFriendRecommended = false;
      const friendDiscount = isFriendRecommended ? 50000 : 0;

      if (is_gov_trial) {
        setMainCtaText("내일배움단 합류하기");
      }

      setIsFriendReco(isFriendRecommended);
      setFriendId(f_uid);
      setDiscountFriend(friendDiscount);

      const calculatedPrice =
        earlybird_price -
        friendDiscount +
        sumOfDefaultOptionPrice -
        event_discount_price;
      setFinalPrice(calculatedPrice);
      const total_price =
        sumOfDefaultOptionPrice > 0
          ? getSumOfDefaultChoiceDisplayPrice()
          : display_price;
      // 정가가 0원인 경우 (AWS 무료 특강) 100%로 띄우는 것으로 수정
      setDiscountPercent(
        display_price > 0
          ? Math.ceil(((total_price - calculatedPrice) / total_price) * 100)
          : 100
      );
      setCouponName(
        `사용가능 쿠폰 ${coupons.length}장 / 보유 ${coupons.length}장`
      );

      setShowCouponSection(coupons.length > 0);
      setShowPointSection(point > 0 && !blockingCourseIds.includes(course_id));
      setShowCouponAndPointSection(
        // 기존 로직
        !course.is_b2b &&
          // 쿠폰 포인트 모두 없는 경우
          !(coupons.length === 0 && point === 0) &&
          ![
            "sparta_free_pass",
            "free_pass",
            "certificate-computer-iPad",
          ].includes(course.keyword)
      );

      if (shouldNotShowDiscountSection(course_id)) {
        setShowCouponAndPointSection(false);
      }

      if (course.is_gov) {
        setShowCouponAndPointSection(false);
      }

      logPaymentPageview(course_id, paymentInfoByCourseId);
    }

    checkCountry();
  }, [
    user,
    isForeign,
    paymentInfoByCourseId.loaded,
    paymentReinforced.loaded,
    paymentOption.loaded,
    paymentInfo?.context?.course?.is_paid,
    paymentInfo?.context?.course?.is_gov,
  ]);

  useEffect(() => {
    if (!paymentInfoByCourseId.loaded) return;

    const {
      price: { display_price },
    } = paymentInfoByCourseId.data.context;
    const total_price = isDefaultOptionIsChecked()
      ? getSumOfOptionDisplayPrice()
      : display_price;
    setDiscountPercent(
      Math.ceil(((total_price - finalPrice) / total_price) * 100)
    );
  }, [selectedChoices, finalPrice]);

  function parsePrice(price, isFinal = false) {
    const EXCHANGE_RATIO = 1100;
    if (isFinal) {
      return numberWithCommas(
        isPaypal ? Math.ceil((price / EXCHANGE_RATIO) * 100) / 100 : price
      );
    } else {
      return numberWithCommas(
        isPaypal ? Math.floor((price / EXCHANGE_RATIO) * 100) / 100 : price
      );
    }
  }

  function changeMethod(method) {
    window.payment_method = method;
    setMethod(method);
    setIsPaypal(method === "paypal");
    setCurrency(method === "paypal" ? "USD" : "KRW");
    setCurrencyUnit(method === "paypal" ? "달러" : "원");
  }

  async function doZeropay(result) {
    const { course } = paymentInfoByCourseId.data.context;
    const { is_gov_trial, is_trial } = course;

    const { order_no } = result;
    const next_endpoint = getZeropayUrl(course_id, order_no);
    const next_options = getGETAuthOptions();
    const endPoint = is_gov_trial
      ? next_endpoint + "?has_card=true"
      : next_endpoint;
    await fetch(endPoint, next_options)
      .then((resp) => resp.json())
      .then(
        await (async (result) => {
          if (result.ok) {
            const { course_id, round_id } = result;
            const urlParams = new URLSearchParams(window.location.search);
            window.location.href = `/payment/prequestion/${course_id}/${round_id}?${urlParams.toString()}`; // go next
            return;
          } else {
            console.log(`result.msg: ${result.msg}`);
            alert(
              "결제가 정상적으로 완료되지 않았습니다. 처음부터 다시 시도해주세요."
            );
          }
          alert("비정상적인 접근입니다. 처음부터 다시 시도해주세요.");
        })
      );
  }

  async function startPayment(isTestPayment = false) {
    if (isPaying) {
      return;
    }
    setIsPaying(true);
    const { course } = payContext;

    if (
      NEED_DELIVERY_COURSE_KEYWORDS.includes(course.keyword) &&
      !deliveryInfo.postCode &&
      !deliveryInfo.address &&
      !deliveryInfo.detailAddress
    ) {
      alert("배송 정보를 입력해주세요.");
      setIsPaying(false);
      return;
    }

    const { enrolled_id } = params();

    let data = {
      course_id,
      isPaypal,
      gold: discountPoint,
      name: user.name,
      phone: user.phone,
      email: user.email,
      payment_method: method,
      making_target: false,
      device_id: getDeviceId(),
      unique_id: getDeviceId(),
      coupon_id,
      f_uid: friendId,
      isFriendReco,
      selectedChoices,
      enrolled_id,
      event_info: {
        _id: payContext.event._id,
        name: payContext.event.name,
        discount_price: payContext.price.event_discount_price,
      },
      deliveryInfo: {
        name: deliveryInfo.name,
        phone: deliveryInfo.phone0 + deliveryInfo.phone1 + deliveryInfo.phone2,
        postCode: deliveryInfo.postCode,
        address: deliveryInfo.address + " " + deliveryInfo.detailAddress,
        memo: deliveryInfo.memo,
      },
      set_kdc_course_id: params().set || "",
      ...args,
    };
    if (!course.is_trial && !course.is_gov_trial && !course.is_b2b) {
      logCheckout(course_id, paymentInfoByCourseId, currency, finalPrice);
    }

    const endpoint = getStartPaymentUrl(course_id);
    await privateInstance.post(endpoint, data).then((resp) => {
      const result = resp.data;
      if (result.message !== undefined) {
        alert(result.message);
        return;
      }

      if (result.zeropay) {
        doZeropay(result);
        return;
      }

      setIsPaying(false);
      const { order_no, price } = result;

      if (
        method === "card" &&
        payContext.course.keyword === "sparta_free_pass"
      ) {
        iamport_request_uplus(order_no, price);
      } else {
        iamport_request_tosspayments(order_no, price, method, isTestPayment);
      }

      logPurchaseUTM(order_no);
    });
  }

  function iamport_request_tosspayments(
    order_no,
    price,
    method = "card",
    isTestPayment = false
  ) {
    const { IMP } = window;
    initIMP();
    const requestPayParams = {
      pg: `tosspayments.${isTestPayment ? "iamporttest_3" : "im_sparta_scc"}`,
      pay_method: method,
      merchant_uid: order_no,
      name: payContext.course.title,
      amount: price,
      tax_free: price,
      buyer_email: user.email,
      buyer_name: user.name,
      buyer_tel: user.phone,
      buyer_addr: "",
      buyer_postcode: "",
      // m_redirect_url: `${config.paymentUrl}/iamport/complete-order/online/mobile`,
      m_redirect_url: `${config.appUrl}/paymentwait/${order_no}/${course_id}${
        round_id ? `/${round_id}` : ""
      }`,
      vbank_due: getVbankDueDate(),
      // TODO: 추후 도메인 별 아임포트 webhook 받을 url 명시할 때 사용할 field
      notice_url: `${config.paymentUrl}/iamport/portone-webhook?domain=online`,
    };

    if (Object.keys(BANK_CODE).includes(method)) {
      requestPayParams.card = {
        direct: {
          code: BANK_CODE[method],
          quota: 0,
        },
      };
    }

    IMP.request_pay(requestPayParams, (response) =>
      after_iamport_payment(response)
    );
  }

  /*
   * 토스페이의 구 모듈이 uplus.
   * 12개월 할부 시 사용 (자유수강권 등)
   * */
  function iamport_request_uplus(order_no, price) {
    const { IMP } = window;
    initIMP();
    IMP.request_pay(
      {
        pg: "uplus",
        pay_method: "card",
        merchant_uid: order_no,
        name: payContext.course.title,
        amount: price,
        tax_free: price,
        buyer_email: user.email,
        buyer_name: user.name,
        buyer_tel: user.phone,
        buyer_addr: "",
        buyer_postcode: "",
        m_redirect_url: `${config.paymentUrl}/iamport/complete-order/online/mobile`,
        vbank_due: getVbankDueDate(),
        // TODO: 추후 도메인 별 아임포트 webhook 받을 url 명시할 때 사용할 field
        // notice_url: `${config.paymentUrl}/complete/online/webhook`,
      },
      (response) => after_iamport_payment(response)
    );
  }

  function after_iamport_payment(response) {
    const { error_code, error_msg, imp_uid, merchant_uid } = response;
    if (error_code || error_msg) {
      alert(
        `결제에 실패하였습니다.\n실패 사유는 다음과 같습니다 : ${error_msg}`
      );
    } else {
      window.location.href = `/paymentwait/${merchant_uid}/${course_id}${
        round_id ? `/${round_id}` : ""
      }`;
      // completion_order({ domain: "online", imp_uid, merchant_uid });
    }
  }

  async function completion_order(data) {
    const response = await axios.post(
      `${config.paymentUrl}/iamport/complete-order`,
      {
        ...data,
      }
    );
    const { status, url } = response.data;

    if (status !== "success") {
      return alert("결제가 실패하였습니다!");
    }

    // if (url.includes("kdc")) {
    //   sendCPLog(
    //     "kdc_purchase_completed",
    //     {
    //       course_title: payContext.course.title,
    //     },
    //     true
    //   );
    // }

    window.location.href = `${url}`;
  }

  function do_request_payment(paymentinfo) {
    const { nicepayinfo, is_iamport, order_no, TID, price } = paymentinfo;
    if (nicepayinfo === null && is_iamport === false) {
      this.setState({
        alert_msg_title: "오류가 발생하였습니다.",
        modalVisible: true,
        alert_msg: "오류가 발생하였습니다. 재시도하시기 바랍니다.",
      });
      return;
    }

    if (nicepayinfo !== null) {
      iamport_request_tosspayments(order_no, price, "card");
    } else if (is_iamport === true) {
      iamport_request_tosspayments(order_no, price);
    }
  }

  function onClickCoupon(coupon, isApply = false, syncedPoint = undefined) {
    /**
     * 쿠폰을 적용하고, 적용가를 반환한다.
     * coupon: 대상 쿠폰
     * isApply: true 일 경우 고객에게 보여주는 금액에 반영한다.
     */
    // 특정 가격 결제인 경우, 포인트 사용을 0원으로 맞춰주어야 함.
    const { benefit_detail, benefit_type } = coupon || {};
    if (benefit_type === "target") {
      if (pointInput.current) {
        pointInput.current.value = 0;
        onChangePoint();
        syncedPoint = 0;
      }
    }

    setShowPointSection(
      benefit_type !== "target" && !blockingCourseIds.includes(course_id)
    );
    const {
      price: { display_price, earlybird_price, event_discount_price },
      coupons,
    } = paymentInfoByCourseId.data.context;
    const total_price = isDefaultOptionIsChecked()
      ? getSumOfOptionDisplayPrice()
      : display_price;
    const usedPoint =
      syncedPoint || syncedPoint >= 0 ? syncedPoint : discountPoint;
    let price_without_coupon =
      earlybird_price +
      getSumOfOptionPrice() -
      discountFriend -
      usedPoint -
      discountEvent -
      event_discount_price;
    // 선택 안함

    if (!coupon || !price_without_coupon) {
      setDiscountPercent(
        Math.ceil(((total_price - price_without_coupon) / total_price) * 100)
      );
      setFinalPrice(price_without_coupon);
      setDiscountCoupon(0);
      setCouponName(
        `사용가능 쿠폰 ${coupons.length}장 / 보유 ${coupons.length}장`
      );
      setCouponId("");
      setCouponType(null);
      return;
    }

    let coupon_unit = "%";
    if (coupon.benefit_type === "amount") {
      coupon_unit = currencyUnit;
    } else if (coupon.benefit_type === "target") {
      coupon_unit = currencyUnit + "으로";
    }
    const coupon_name = `${parsePrice(
      coupon.benefit_detail
    )}${coupon_unit} 할인 / ${coupon.expired_at} 까지`;
    let f_p, d_c;
    setCouponType(benefit_type);
    if (benefit_type === "percent") {
      d_c = Math.floor(price_without_coupon * (benefit_detail / 100));
      f_p = price_without_coupon - d_c;
    } else if (benefit_type === "amount") {
      d_c = Math.min(price_without_coupon, benefit_detail);
      f_p = price_without_coupon - d_c;
    } else if (benefit_type === "target") {
      d_c = price_without_coupon - benefit_detail;
      f_p = benefit_detail;
    }

    if (isApply) {
      setDiscountPercent(Math.ceil(((total_price - f_p) / total_price) * 100));
      setFinalPrice(f_p);
      setDiscountCoupon(d_c);
      setCouponName(coupon_name);
      setCouponId(coupon._id);
    }
    return f_p;
  }

  function onClickChoice(optionId, choiceId) {
    const currentSumOfOptionPrice = getSumOfOptionPrice();
    const siblingChoices = paymentOption.data.filter((option) => {
      return option._id === optionId;
    })[0].choices;
    const siblingChoiceIds = [];
    for (const choice of siblingChoices) {
      siblingChoiceIds.push(choice._id);
    }
    const filteredSelectedChoices = selectedChoices.filter(
      (selectedChoiceId) => !siblingChoiceIds.includes(selectedChoiceId)
    );
    filteredSelectedChoices.push(choiceId);
    setSelectedChoices(filteredSelectedChoices);

    let sumOfPrice = 0;
    for (const option of paymentOption.data) {
      for (const choice of option.choices) {
        if (filteredSelectedChoices.includes(choice._id)) {
          sumOfPrice += choice.price;
          setIsSelectedChoiceDefault(choice.is_default);
        }
      }
    }
    const fp =
      finalPrice +
      discountPoint +
      discountCoupon -
      currentSumOfOptionPrice +
      sumOfPrice;

    // 수동으로 포인트 및 쿠폰 초기화
    const { coupons } = paymentInfoByCourseId.data.context;
    setDiscountCoupon(0);
    setCouponName(
      `사용가능 쿠폰 ${coupons.length}장 / 보유 ${coupons.length}장`
    );
    let usedPoint = 0;
    if (pointInput.current !== undefined && pointInput.current !== null) {
      pointInput.current.value = 0;
      usedPoint = onChangePoint(false);
    }
    setDiscountPoint(0);
    setIsFullDiscount(false);
    setCouponId("");
    setCouponType(null);
    setFinalPrice(fp);
  }

  function hasFreeCoupon() {
    if (paymentInfoByCourseId.loaded) {
      const { coupons } = paymentInfoByCourseId.data.context;
      for (const coupon of coupons) {
        if (
          coupon.benefit_type === "percent" &&
          coupon.benefit_detail === 100 &&
          coupon.usable === true
        ) {
          return true;
        }
      }
    }
    return false;
  }

  function getSumOfOptionPrice() {
    let sumOfPrice = 0;
    for (const option of paymentOption.data) {
      for (const choice of option.choices) {
        if (selectedChoices.includes(choice._id)) {
          sumOfPrice += choice.price;
        }
      }
    }
    return sumOfPrice;
  }

  function getSumOfDefaultChoicePrice() {
    let sumOfPrice = 0;
    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        for (const choice of option.choices) {
          if (choice.is_default) {
            sumOfPrice += choice.price;
          }
        }
      }
    }
    return sumOfPrice;
  }

  function getSumOfDefaultChoiceDisplayPrice() {
    let sumOfPrice = 0;

    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        for (const choice of option.choices) {
          if (choice.is_default) {
            sumOfPrice += choice.display_price;
          }
        }
      }
    }
    return sumOfPrice;
  }

  function getSumOfOptionDisplayPrice() {
    let sumOfPrice = 0;
    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        for (const choice of option.choices) {
          if (selectedChoices.includes(choice._id)) {
            sumOfPrice += choice.display_price;
          }
        }
      }
    }
    return sumOfPrice;
  }

  function getSumOfOptionDisplayEarlyBirdPrice() {
    let sumOfPrice = 0;
    if (payContext) {
      sumOfPrice -= payContext.price.earlybird_discounted;
    }
    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        for (const choice of option.choices) {
          if (selectedChoices.includes(choice._id)) {
            sumOfPrice += choice.display_earlybird_price;
          }
        }
      }
    }
    return sumOfPrice;
  }

  function isDefaultOptionIsChecked() {
    if (paymentOption.loaded) {
      for (const option of paymentOption.data) {
        if (option.is_default) {
          for (const choice of option.choices) {
            if (selectedChoices.includes(choice._id)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  const pointInput = useRef();

  function onChangePoint(isAll = false) {
    if (pointInput.current === undefined) {
      // pointInput이 없는 경우
      return;
    }

    const re = /^[0-9\b]+$/;
    let used = pointInput.current ? pointInput.current.value : 0;
    if (!re.test(used)) {
      pointInput.current.value = "";
      used = 0;
    } else {
      used = Number(used);
    }

    const {
      price: { display_price, earlybird_price, event_discount_price },
      point,
      coupons,
    } = paymentInfoByCourseId.data.context;
    const total_price = isDefaultOptionIsChecked()
      ? getSumOfOptionDisplayPrice()
      : display_price;
    // 현재 선택된 쿠폰 가져오기
    const coupon = coupons.find((c) => c._id === coupon_id);
    if (coupon && coupon.benefit_type === "percent") {
      // 퍼센트 쿠폰이 선택되어있는 경우
      // 1. 쿠폰이 없는 상태로 point 계산
      const price_without_coupon_and_point =
        earlybird_price +
        getSumOfOptionPrice() -
        discountFriend -
        discountEvent -
        event_discount_price;

      const limit = Math.min(point, price_without_coupon_and_point);
      if (used > limit || isAll) {
        used = limit;
        pointInput.current.value = limit;
      }

      // 2. point가 적용된 가격에 다시 퍼센트 쿠폰 적용
      const price_without_coupon = price_without_coupon_and_point - used;
      const d_c = Math.floor(
        price_without_coupon * (coupon.benefit_detail / 100)
      );
      const f_p = price_without_coupon - d_c;

      // 3. 새로 계산한 퍼센트 할인 가격으로 변경
      setDiscountCoupon(d_c);
      setDiscountPercent(Math.ceil(((total_price - f_p) / total_price) * 100));
      setDiscountPoint(used);
      setFinalPrice(f_p);
      pointInput.current.value = used;
      return used;
    }

    const price_without_point =
      earlybird_price +
      getSumOfOptionPrice() -
      discountFriend -
      discountEvent -
      discountCoupon -
      event_discount_price;

    const limit = Math.min(point, price_without_point);
    if (used > limit || isAll) {
      used = limit;
      pointInput.current.value = limit;
    }
    const f_p = price_without_point - used;
    setDiscountPercent(Math.ceil(((total_price - f_p) / total_price) * 100));
    setDiscountPoint(used);
    setFinalPrice(f_p);
    pointInput.current.value = used;
    return used;
  }

  function onClickCancelFullDiscount() {
    /**
     * 최대할인 적용 취소.
     */
    setIsFullDiscount(false);

    let usedPoint = 0;
    if (showPointSection) {
      // 포인트 적용 해제
      if (pointInput.current !== undefined && pointInput.current !== null) {
        pointInput.current.value = 0;
        usedPoint = onChangePoint(false);
      }
    }

    if (showCouponSection) {
      // 선택한 쿠폰 해제
      // 비동기 문제로 usedPoint를 넘겨줘야함
      onClickCoupon(null, false, usedPoint);
    }
  }

  function onClickFullDiscount() {
    /**
     * 자동 최대할인 도출.
     * 쿠폰이 비율제인 경우 반드시 포인트를 먼저 적용해야 하기 때문에,
     * 포인트 -> 쿠폰(금액>만료일) 순으로 적용.
     */
    setIsFullDiscount(true); // 최대할인 적용 버튼을 누를 때만 적용 취소로 바뀜
    let usedPoint = 0;
    if (showPointSection) {
      usedPoint = onChangePoint(true);
    }
    if (showCouponSection) {
      const { coupons } = paymentInfoByCourseId.data.context;
      if (coupons.length > 0) {
        let idx = 0;
        let min_price = Number.MAX_SAFE_INTEGER;
        let min_expire = new Date(9999, 12, 31);
        for (let i = 0; i < coupons.length; i++) {
          let applied_price = onClickCoupon(coupons[i], false);
          if (applied_price < min_price) {
            min_price = applied_price;
            idx = i;
          }
          if (applied_price === min_price) {
            const dates = coupons[i].expired_at.split(".");
            const applied_expire = new Date(dates[0], dates[1] - 1, dates[2]);
            if (applied_expire < min_expire) {
              min_expire = applied_expire;
              idx = i;
            }
          }
        }
        onClickCoupon(coupons[idx], true, usedPoint);
      }
    }
  }

  function toggleOptions(type, e) {
    if (type === "userProfile") {
      setIsShowUserProfile(!isShowUserProfile);
    } else if (type === "userTooltip") {
      e.stopPropagation();
      setIsShowUserTooltip(!isShowUserTooltip);
    } else if (type === "couponOptions") {
      setIsShowCouponOption(!isShowCouponOption);
    } else if (type === "installment") {
      setIsShowInstallment(!isShowInstallment);
    }
  }

  const receiptArea = useRef();
  const reviewDivider = useRef();

  function positioningReceipt() {
    if (isMobile || !receiptArea.current || !reviewDivider.current) {
      return;
    }
    const receiptHeight = receiptArea.current.offsetHeight;
    const receiptBottom = 150 + receiptHeight;
    const reviewTop = reviewDivider.current.getBoundingClientRect().top;
    if (document.getElementById("receipt")) {
      if (reviewTop < receiptBottom) {
        document.getElementById("receipt").style.top = `${
          reviewTop - receiptHeight
        }px`;
        document.getElementById("timer").style.top = `${
          reviewTop - receiptHeight - 85
        }px`;
      } else {
        document.getElementById("receipt").style.top = "150px";
        document.getElementById("timer").style.top = "85px";
      }
    }
  }

  const receiptDivider = useRef();
  const receiptMobileCtaArea = useRef();
  const receiptMobileCtaBtn = useRef();

  function toggleReciptArea() {
    if (
      !isMobile ||
      !receiptDivider.current ||
      !receiptMobileCtaArea.current ||
      !receiptMobileCtaBtn.current
    ) {
      return;
    }
    const userScroll = window.scrollY + window.innerHeight;
    const stdMin =
      receiptDivider.current.offsetTop +
      receiptMobileCtaArea.current.offsetHeight +
      100;
    const stdMax = receiptMobileCtaBtn.current.offsetTop;
    setIsMobileShowFixedCta(userScroll < stdMin || window.scrollY > stdMax);
  }

  const remainingRef = useRef();

  function setTimer() {
    const now = new Date();
    const dday = new Date(payContext.course.enroll_end_date.replace(/\s/, "T"));
    const days = (dday - now) / 1000 / 60 / 60 / 24;
    const daysRound = Math.floor(days).toString();
    const hours = (dday - now) / 1000 / 60 / 60 - 24 * daysRound;
    const hoursRound = Math.floor(hours).toString();
    const minutes =
      (dday - now) / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    const minutesRound = Math.floor(minutes).toString();
    const seconds =
      (dday - now) / 1000 -
      24 * 60 * 60 * daysRound -
      60 * 60 * hoursRound -
      60 * minutesRound;
    const secondsRound = Math.round(seconds).toString();
    if (remainingRef.current) {
      remainingRef.current.innerHTML = `${daysRound.padStart(
        1,
        "0"
      )}일 ${hoursRound.padStart(2, "0")}:${minutesRound.padStart(
        2,
        "0"
      )}:${secondsRound.padStart(2, "0")}`;
    }
  }

  const endTimeRef = useTimerRefVer2(
    payContext?.price?.earlybird_enroll_end_date,
    [paymentInfoByCourseId.loaded],
    "regular",
    false,
    "#ffffff"
  );

  // 내배단 합류하기 강의는 별도 처리
  if (paymentInfoByCourseId.data?.context?.course.is_gov_trial) {
    const urlParams = new URLSearchParams(window.location.search);
    const initialStatus =
      paymentInfoByCourseId.data?.context?.course.initial_status;

    if (initialStatus) {
      const next = () => {
        return encodeURIComponent(
          `${process.env.REACT_APP_SCC_URL}/nb/guide${
            initialStatus === "has_card" ? "/hrdRegFin" : ""
          }?${urlParams.toString()}&is_registered=true`
        );
      };
      return history.replace(`/classroom?next=${next()}`);
    } else {
      return <CreditQuizLanding user={user} />;
    }
  }

  return (
    <div className={cx("bg")}>
      <KdcModal
        isModalShow={isKdcModalShow}
        setIsModalShow={setIsKdcModalShow}
      />
      <NavContainer />
      {paymentInfoByCourseId.loaded &&
        payContext.course.is_temp_package &&
        payContext.course.keyword !== "package_test" &&
        payContext.course.keyword !== "extend" && (
          <>
            <section className={cx("intro", "sec")}>
              <h2>수강신청하기</h2>
            </section>

            {/* <PkgBanner keyword={payContext.course.keyword} /> */}
          </>
        )}
      {/* 친구추천 배너 */}
      {isFriendReco && <FriendsBanner />}
      <Modal
        visible={couponModalVisible}
        handleVisible={() => {}}
        header={`🎉 3만원 깜짝쿠폰이 발급되었어요!`}
        mobileHeader={
          <span>
            🎉 3만원 🎉
            <br />
            깜짝쿠폰이 발급되었어요!
          </span>
        }
        content={
          <div className={cx("modal-coupon")}>
            <span className={cx("modal-coupon__content")}>
              단 이틀 동안만 쓸 수 있는 3만원 쿠폰이 발급되었어요
              <br />
              쿠폰 유효기간 : {couponExpiredAt} 까지!
            </span>
          </div>
        }
        mobileContent={
          <div className={cx("modal-coupon")}>
            <span className={cx("modal-coupon__content")}>
              단 이틀 동안만 쓸 수 있는
              <br />
              3만원 쿠폰이 발급되었어요
              <br />
              유효기간 : {couponExpiredAt} 까지!
            </span>
          </div>
        }
        isCtaHidden={false}
        img_path={"/v2/assets/rtan/rtan_surprise_coupon.png"}
        cta={{
          btn_text: "바로 사용할게요!",
          handleCTAClick: () => {
            setCouponModalVisible(false);
            onClickFullDiscount();
          },
        }}
        tailInfo={
          <div className={cx("modal-coupon")}>
            <span className={cx("modal-coupon__tail-info")}>
              쿠폰은 ‘마이페이지 {">"} 내 쿠폰’ 에서 확인할 수 있어요.
            </span>
            t{" "}
          </div>
        }
      />
      <Ga4Modal
        isShow={isShowGa4Modal}
        closeModal={() => setIsShowGa4Modal(false)}
      />
      <PaymentDeliveryCheckModal
        isPaying={isPaying}
        visible={showDeliveryModal}
        handleVisible={setShowDeliveryModal}
        deliveryInfo={deliveryInfo}
        startPayment={startPayment}
      />
      {/* 주소 검색 */}
      {isOpenPost && (
        <SearchPost
          setDeliveryInfo={setDeliveryInfo}
          setIsOpenPost={setIsOpenPost}
        />
      )}

      <div className={cx("for-pc-layout")}>
        {!(paymentInfoByCourseId.loaded && user) && <Skeleton />}
        {user && paymentInfoByCourseId.loaded && (
          <>
            {isPhoneVerificationRequired && <PhoneVerification />}
            {paymentInfoByCourseId.loaded &&
              (payContext.course.is_temp_package &&
              payContext.course.keyword !== "package_test" ? (
                <>
                  {/* <PkgDetail course_id={course_id} /> */}
                  <PackageDetail course_id={course_id} />
                </>
              ) : (
                <>
                  <section className={cx("sec")}>
                    <div className={cx("info-header")}>
                      <div className={cx("info-header-text")}>
                        <h2>강의 정보</h2>
                      </div>
                    </div>
                    <div className={cx("row")}>
                      <CourseSummary selectedChoices={selectedChoices} />
                    </div>
                  </section>
                  {isGov && <KdcPaymentWarn />}
                </>
              ))}
            {isShowOptionSection &&
              paymentOption.loaded &&
              paymentOption.data &&
              paymentOption.data.map((option, idx) => {
                const title = option.title;
                if (title === "수강권 수량") {
                  return (
                    <>
                      <div className={cx("pc-divider")} />
                      <section className={cx("sec", "moreManaged")}>
                        <div className={cx("info-header")}>
                          <div className={cx("info-header-text")}>
                            <h2>{option.title}</h2>
                          </div>
                        </div>
                        <div className={cx("info-components")}>
                          {option.choices.map((choice, idx) => {
                            return (
                              <div
                                className={cx("info-component")}
                                onClick={() => {
                                  onClickChoice(option._id, choice._id);
                                }}
                              >
                                <div
                                  className={cx(
                                    "checkbox",
                                    `${
                                      selectedChoices.includes(choice._id)
                                        ? "active"
                                        : ""
                                    }`
                                  )}
                                >
                                  <div className={cx("checkbox-inner")} />
                                </div>
                                <div className={cx("more-managed")}>
                                  <span className={cx("info-key")}>
                                    <span className={cx("info-key__title")}>
                                      {choice.title}
                                    </span>
                                    <br className={cx("info-key__splitter")} />
                                    <span className={cx("info-key__before")}>
                                      {" "}
                                      {parsePrice(
                                        choice.display_price,
                                        false
                                      )}{" "}
                                      {currencyUnit}
                                    </span>
                                    <span className={cx("info-key__after")}>
                                      &nbsp;&nbsp; ➞ &nbsp;&nbsp;
                                      {parsePrice(
                                        choice.display_earlybird_price,
                                        false
                                      )}{" "}
                                      {currencyUnit}
                                      &nbsp; (
                                      {Math.ceil(
                                        ((choice.display_price -
                                          choice.display_earlybird_price) /
                                          choice.display_price) *
                                          100
                                      )}
                                      % 할인)
                                    </span>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    </>
                  );
                }
              })}
            {paymentInfoByCourseId.loaded && (
              <>
                {showCouponAndPointSection && (
                  <PaymentDiscountSection
                    isFullDiscount={isFullDiscount}
                    currencyUnit={currencyUnit}
                    onClickFullDiscount={onClickFullDiscount}
                    onClickCancelFullDiscount={onClickCancelFullDiscount}
                    showCouponSection={showCouponSection}
                    showPointSection={showPointSection}
                    toggleOptions={toggleOptions}
                    discountCoupon={discountCoupon}
                    couponName={couponName}
                    isShowCouponOption={isShowCouponOption}
                    setIsFullDiscount={setIsFullDiscount}
                    onClickCoupon={onClickCoupon}
                    coupons={payContext.coupons}
                    point={payContext.point}
                    pointInput={pointInput}
                    onChangePoint={onChangePoint}
                    parsePrice={parsePrice}
                  />
                )}
                {NEED_DELIVERY_COURSE_KEYWORDS.includes(
                  payContext.course.keyword
                ) && (
                  <PaymentDeliveryInformation
                    deliveryInfo={deliveryInfo}
                    setDeliveryInfo={setDeliveryInfo}
                    setDeliveryChecked={setDeliveryChecked}
                    setIsOpenPost={setIsOpenPost}
                    gift={
                      [
                        "sparta_free_pass",
                        "certificate-computer-iPad",
                      ].includes(payContext.course.keyword)
                        ? "iPad"
                        : "book"
                    }
                  />
                )}

                {showCouponAndPointSection && (
                  <div className={cx("pc-divider")} />
                )}
                {isPhoneVerificationRequired && <PaymentNoticeContainer />}
                <OnlyDesktop>
                  {!payContext.course.is_gov_trial && (
                    <PaymentPayMethodContainer
                      method={method}
                      isMobile={isMobile}
                      course={payContext.course}
                      toggleOptions={toggleOptions}
                      isForeign={isForeign}
                      changeMethod={changeMethod}
                      isShowInstallment={isShowInstallment}
                      isGov={isGov}
                    />
                  )}
                </OnlyDesktop>
                {isGov && <CheckBeforePaymentDesktop />}
                <div className={cx("receipt-wrapper")}>
                  {!isReinforced &&
                    payContext.course.is_earlybird &&
                    !payContext.course.is_gov_trial &&
                    !payContext.course.is_grammer &&
                    !payContext.course.is_temp_package &&
                    !(
                      payContext.course.keyword === "free_pass" ||
                      payContext.course.keyword === "extend" ||
                      payContext.course.keyword === "sparta_free_pass"
                    ) &&
                    !isGov && (
                      <div
                        className={cx("timer", "--hidden")}
                        ref={timerRef}
                        id={"timer"}
                      >
                        <div className={cx("emoji")}>⏰</div>
                        <div>
                          {paymentInfoByCourseId.loaded &&
                          payContext.price.earlybird_criteria === "time" ? (
                            payContext.price.earlybird_enroll_end_date ===
                            payContext.course.enroll_end_date ? (
                              <>{payContext.course.round} 마감까지</>
                            ) : (
                              <>{payContext.price.earlybird_title} 마감까지</>
                            )
                          ) : (
                            "개강일까지"
                          )}{" "}
                          <span
                            ref={
                              payContext.price.earlybird_criteria === "time"
                                ? endTimeRef
                                : remainingRef
                            }
                          ></span>{" "}
                          남았어요!
                        </div>
                      </div>
                    )}
                  <OnlyMobile>
                    <section
                      className={cx("sec", "receipt")}
                      id={"receipt"}
                      ref={receiptArea}
                      data-testid="receipt-component"
                    >
                      {!payContext.course.is_gov_trial && (
                        <PaymentPayMethodContainer
                          method={method}
                          isMobile={isMobile}
                          course={payContext.course}
                          toggleOptions={toggleOptions}
                          isForeign={isForeign}
                          changeMethod={changeMethod}
                          isShowInstallment={isShowInstallment}
                          isGov={isGov}
                        />
                      )}
                      {isGov && <CheckBeforePaymentMobile />}
                    </section>
                  </OnlyMobile>
                  <section
                    className={cx("sec", "receipt")}
                    id={"receipt"}
                    ref={receiptArea}
                    data-testid="receipt-component"
                  >
                    <div className={cx("info-header")}>
                      <div className={cx("info-header-text")}>
                        <h2>
                          {!payContext.course.is_gov_trial
                            ? "결제 정보"
                            : "빠른 합류 개강:"}
                        </h2>
                      </div>
                    </div>
                    {paymentInfoByCourseId.loaded &&
                      payContext.course.enroll_start_date &&
                      !payContext.course.is_trial &&
                      !payContext.course.is_b2b &&
                      !payContext.course.is_grammer &&
                      payContext.course.keyword !== "free_pass" && (
                        <>
                          {payContext.price.earlybird_criteria !== "time" &&
                            payContext.course.title !== "내일배움단 합류하기" &&
                            payContext.course.keyword !== "extend" &&
                            !isGov && (
                              <div className={cx("social-proof")}>
                                <img
                                  className={cx("ic-alert")}
                                  src={"/assets/icons/ic_alert.png"}
                                  alt="icon for alert"
                                />
                                {
                                  <span>
                                    {payContext.course.is_temp_package ||
                                    selectedChoices.length > 0 ||
                                    payContext.price.earlybird_seat_left <= 0 ||
                                    payContext.price.earlybird_seat_left > 100
                                      ? ""
                                      : `${payContext.price.earlybird_seat_left}자리 남음,`}
                                    {payContext.price.earlybird_order !== 0
                                      ? ` ${payContext.price.earlybird_order}차 얼리버드는 ${payContext.price.earlybird_time} 만에 마감되었어요.`
                                      : selectedChoices.length > 0
                                      ? `런칭기념 슈퍼 얼리버드 할인이 진행중이에요!`
                                      : payContext.course.keyword ===
                                        "sparta_free_pass"
                                      ? `1,500만 원 상당 전 강의 무제한 수강 + iPad 증정`
                                      : ` 다시 오지 않는 최저가! 바로 지금이에요!`}
                                  </span>
                                }
                              </div>
                            )}
                        </>
                      )}
                    <div className={cx("info-components")}>
                      {!isB2B && !payContext.course.is_gov_trial && (
                        <>
                          <div className={cx("info-component")}>
                            <div className={cx("info-key")}>총 상품 금액</div>
                            <div className={cx("info-value")}>
                              {parsePrice(
                                isDefaultOptionIsChecked()
                                  ? getSumOfOptionDisplayPrice()
                                  : payContext.price.display_price,
                                false
                              )}{" "}
                              {currencyUnit}
                            </div>
                          </div>
                          {!payContext.course.is_gov &&
                            !["extend", "sparta_free_pass"].includes(
                              payContext.course.keyword
                            ) &&
                            !earlybirdExcludedCourses.includes(course_id) && (
                              <div className={cx("info-component")}>
                                <div className={cx("info-key")}>
                                  {payContext.course.is_temp_package
                                    ? "패키지 할인 금액"
                                    : payContext.course.has_early_bird === false
                                    ? "최저가"
                                    : payContext.course.is_grammer
                                    ? "최저가"
                                    : "얼리버드 할인 금액"}
                                </div>
                                <div className={cx("info-value", "bold")}>
                                  -&nbsp;&nbsp;&nbsp;
                                  {parsePrice(
                                    isDefaultOptionIsChecked()
                                      ? getSumOfOptionDisplayPrice() -
                                          getSumOfOptionDisplayEarlyBirdPrice()
                                      : payContext.price.display_price -
                                          payContext.price.earlybird_price,
                                    false
                                  )}{" "}
                                  {currencyUnit}
                                </div>
                              </div>
                            )}
                        </>
                      )}
                      {payContext.price.event_discount_price > 0 && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>
                            {payContext.event.name} 할인
                          </div>
                          <div className={cx("info-value", "bold")}>
                            -&nbsp;&nbsp;&nbsp;
                            {parsePrice(
                              payContext.price.event_discount_price,
                              false
                            )}{" "}
                            {currencyUnit}
                          </div>
                        </div>
                      )}
                      {discountPoint > 0 && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>포인트 할인 금액</div>
                          <div className={cx("info-value", "bold")}>
                            -&nbsp;&nbsp;&nbsp;
                            {parsePrice(discountPoint, false)} {currencyUnit}
                          </div>
                        </div>
                      )}
                      {discountCoupon > 0 && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>쿠폰 할인 금액</div>
                          <div className={cx("info-value", "bold")}>
                            -&nbsp;&nbsp;&nbsp;
                            {parsePrice(discountCoupon, false)} {currencyUnit}
                          </div>
                        </div>
                      )}
                      {isFriendReco && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>
                            친구추천 할인 금액
                          </div>
                          <div className={cx("info-value", "bold")}>
                            -&nbsp;&nbsp;&nbsp;
                            {parsePrice(discountFriend, false)} {currencyUnit}
                          </div>
                        </div>
                      )}
                      {!payContext.course.is_gov_trial && !isGov && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>결제 가격</div>
                          <div className={cx("info-value")}>
                            {parsePrice(finalPrice, true)} {currencyUnit}
                          </div>
                        </div>
                      )}
                      {isGov && (
                        <div className={cx("info-component")}>
                          <div className={cx("info-key")}>
                            할인 금액(국비지원)
                          </div>
                          <div className={cx("info-value", "bold")}>
                            -
                            {parsePrice(
                              payContext.price.display_price - finalPrice,
                              true
                            )}
                            {currencyUnit}
                          </div>
                        </div>
                      )}
                    </div>
                    {!payContext.course.is_gov_trial && (
                      <div className={cx("divider")} ref={receiptDivider} />
                    )}
                    {isGov && <KdcFinalPrice price={finalPrice} />}
                    {!isGov && (
                      <div
                        className={cx("installment-summary")}
                        ref={receiptMobileCtaArea}
                      >
                        <div className={cx("installment-summary-key")}>
                          {isPaypal
                            ? "결제 금액"
                            : payContext.course.keyword === "sparta_free_pass"
                            ? "12개월 할부"
                            : "6개월 할부"}
                        </div>
                        <div className={cx("installment-summary-value")}>
                          {discountPercent > 0 && (
                            <div className={cx("installment-ratio")}>
                              {discountPercent}%
                            </div>
                          )}
                          <div className={cx("installment-price")}>
                            {isPaypal ? "" : "월"}&nbsp;&nbsp;
                            <span>
                              {isPaypal
                                ? parsePrice(finalPrice, true)
                                : payContext.course.keyword ===
                                  "sparta_free_pass"
                                ? parsePrice(parseInt(finalPrice / 12), true)
                                : parsePrice(parseInt(finalPrice / 6), true)}
                            </span>
                            {currencyUnit}
                          </div>
                        </div>
                      </div>
                    )}
                    {!payContext.course.is_gov_trial && !isGov && (
                      <div className={cx("info-footer")}>
                        {!["extend", "free_pass", "sparta_free_pass"].includes(
                          payContext.course.keyword
                        ) && (
                          <>
                            {!payContext.course.is_monthly_trial &&
                              !payContext.course.is_grammer && (
                                <div className={cx("info-footer-text")}>
                                  완주 시 {POINT_REIMBURSE} 포인트 추가 환급
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    )}{" "}
                    <OnlyDesktop>
                      <div className={cx("cta-container")}>
                        <div
                          data-testid="payment-cta"
                          className={cx("cta")}
                          onClick={() => {
                            startPayment();
                          }}
                          ref={receiptMobileCtaBtn}
                        >
                          {isPaying ? "신청 중입니다" : mainCtaText}
                        </div>
                      </div>
                      {isDev && (
                        <div className={cx("cta-container")}>
                          <div
                            className={cx("cta")}
                            style={{ backgroundColor: "tan" }}
                            onClick={() => {
                              startPayment(true);
                            }}
                          >
                            {isPaying
                              ? "신청 중입니다"
                              : `${mainCtaText} (테스트 결제)`}
                          </div>
                        </div>
                      )}
                      <PaymentAgreementContainer />
                    </OnlyDesktop>
                    {isMobileShowFixedCta && !couponModalVisible && (
                      <div className={cx("mobileFixedCta")}>
                        <div className={cx("installment-summary")}>
                          <div className={cx("installment-summary-key")}>
                            {payContext.course.keyword === "sparta_free_pass"
                              ? "12개월 할부"
                              : "6개월 할부"}
                          </div>
                          <div className={cx("installment-summary-value")}>
                            <div className={cx("earlybird")}>
                              얼리버드 할인 적용
                            </div>
                            <div className={cx("installment-main")}>
                              {discountPercent > 0 && (
                                <div className={cx("installment-ratio")}>
                                  {discountPercent}%
                                </div>
                              )}
                              <div className={cx("installment-price")}>
                                월&nbsp;&nbsp;
                                <span>
                                  {payContext.course.keyword ===
                                  "sparta_free_pass"
                                    ? parsePrice(
                                        parseInt(finalPrice / 12),
                                        true
                                      )
                                    : parsePrice(
                                        parseInt(finalPrice / 6),
                                        true
                                      )}
                                </span>
                                {currencyUnit}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={cx("installment-info")}>
                          {!payContext.course.is_trial &&
                            !payContext.course.is_grammer &&
                            payContext.course.is_reimbursable &&
                            payContext.course.is_extendable && (
                              <div className={cx("installment-info-text")}>
                                "완주 시 {POINT_REIMBURSE} 포인트 추가 환급"
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </section>
                  <OnlyMobile>
                    <section
                      className={cx("sec", "receipt")}
                      id={"receipt"}
                      ref={receiptArea}
                      data-testid="receipt-component"
                    >
                      <div className={cx("cta-container")}>
                        <div
                          data-testid="payment-cta"
                          className={cx("cta")}
                          onClick={() => {
                            startPayment();
                          }}
                          ref={receiptMobileCtaBtn}
                        >
                          {isPaying ? "신청 중입니다" : mainCtaText}
                        </div>
                      </div>
                      {isDev && (
                        <div className={cx("cta-container")}>
                          <div
                            className={cx("cta")}
                            style={{ backgroundColor: "tan" }}
                            onClick={() => {
                              startPayment(true);
                            }}
                          >
                            {isPaying
                              ? "신청 중입니다"
                              : `${mainCtaText} (테스트 결제)`}
                          </div>
                        </div>
                      )}
                      <PaymentAgreementContainer />
                    </section>
                  </OnlyMobile>
                </div>

                <PaymentReviewContainer
                  course_id={course_id}
                  isMobile={isMobile}
                  reviewDivider={reviewDivider}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    authentication,
    paymentInfoByCourseId,
    paymentReinforced,
    isNomadCouponTarget,
    nomadCouponResult,
    paymentOption,
  } = state;
  const { user } = authentication;
  return {
    user,
    paymentInfoByCourseId,
    paymentReinforced,
    isNomadCouponTarget,
    nomadCouponResult,
    paymentOption,
  };
}

const connectedPaymentPage_v2 = connect(mapStateToProps, {
  getPaymentInfoByCourseId: paymentActions.getPaymentInfoByCourseId,
  getPaymentReinforced: paymentActionsV2.getPaymentReinforced,
  getPaymentOption: paymentActionsV2.getPaymentOption,
  getIsNomadCouponTarget: paymentActionsV2.getIsNomadCouponTarget,
  postNomadCoupon: paymentActionsV2.postNomadCoupon,
})(PaymentPage_v2);
export { connectedPaymentPage_v2 as PaymentPage_v2 };
