import React from "react";
import classNames from "classnames/bind";
import styles from "./LoginPage.module.scss";
import {
  courseActions,
  initEnvironment,
  todaydiscountActions,
  userActions,
} from "../../_actions";
import { createEventId, handleResponse, history, params } from "../../_helpers";
import { config } from "../../config";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { connect } from "react-redux";
import { API_VERSION } from "../../_urls";
import axios from "../../_helpers/axios";
import {
  getDeviceId,
  sendLog,
  setCountry,
} from "../../v2/businesslogics/logging";

const { Option } = Select;
const cx = classNames.bind(styles);
const validateMessages = {
  required: "Required.",
  types: {
    email: "Invalid email address",
    phone: "Please check the phone number",
    password:
      "Password must contain at least 6 characters, including both alphabets and numbers.",
  },
};

class LoginPageEn extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoginPage: history.location.pathname === "/en/login",
      isSignupPage: history.location.pathname === "/en/signup",
      checkedAccountExist: false,
      isLoading: false,
      errorMsg: undefined,
      emailShow: true,
      log_sent: false,
    };
    history.listen((/* location, action */) => {
      this.resetState();
    });
    const { _initEnvironment } = props;
    const eventID = createEventId();
    _initEnvironment();
    this.checkUserLoggined();
    this._loglanding(eventID);
  }

  componentDidMount() {
    setCountry();
    window.scrollTo(0, 0);
    const { comkt } = params();
    this.resetState();
    this._logPageVisit();
    if (comkt !== undefined) {
      this.setState({
        comkt,
      });
    }
    this._setHackle();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      !this.props.isFetching
    ) {
      this.setState({
        isLoading: false,
      });
      if (this.props.error) {
        this.setState({
          errorMsg: this.props.error,
        });
      }
    }
  }

  componentWillUnmount() {}

  _setHackle = () => {};

  assignUserGroup = () => {
    const device_id = getDeviceId();
    let sum = 0;
    for (let i = 0; i < device_id.length; i++) {
      sum += device_id.charCodeAt(i);
    }
    const groups = ["A", "B"];
    sum = "sum" in params() ? parseInt(params().sum) : sum;
    return groups[sum % 2];
  };

  _loglanding = (eventID) => {};

  _sendLog = async (action, eventID) => {
    const device_id = getDeviceId();
    const referrer = "";
    const url = document.location.href;
    const { user } = this.props;
    const group = this.assignUserGroup();
    let user_id = user ? user._id : "";
    let ua = window.navigator.userAgent;
    ua = ua.toLowerCase();
    let matched = {};
    const platform_match =
      /(ipad)/.exec(ua) ||
      /(ipod)/.exec(ua) ||
      /(windows phone)/.exec(ua) ||
      /(iphone)/.exec(ua) ||
      /(kindle)/.exec(ua) ||
      /(silk)/.exec(ua) ||
      /(android)/.exec(ua) ||
      /(win)/.exec(ua) ||
      /(mac)/.exec(ua) ||
      /(linux)/.exec(ua) ||
      /(cros)/.exec(ua) ||
      /(playbook)/.exec(ua) ||
      /(bb)/.exec(ua) ||
      /(blackberry)/.exec(ua) ||
      [];

    matched.platform = platform_match[0] || "";

    let data = {
      action,
      user_id,
      device_id,
      url,
      referrer,
      group,
      platform: matched.platform,
      event_id: eventID,
    };
    const { cid } = params();
    if (cid) {
      data.course_id = cid;
    }
    await axios.post("/logs/", data);
  };

  async checkUserLoggined() {
    this._setHackle();
    const { user } = this.props;
    const { checkPlatform } = window;
    const { next, cid, maker, reviews } = params();
    if (user && Object.keys(user).length !== 0) {
      const eventID = createEventId();
      await this._sendLog("login_skip_by_logined", eventID);
      if (next === "payment" && cid !== undefined) {
        if (maker !== undefined) {
          if (reviews !== undefined) {
            history.replace({ pathname: "/" });
            history.push(`/payment?cid=${cid}&maker=y&reviews=y`);
          } else {
            history.replace({ pathname: "/" });
            history.push(`/payment?cid=${cid}&maker=y`);
          }
        } else {
          history.replace({ pathname: "/" });
          history.push(`/payment?cid=${cid}`);
        }
      } else if (next === "prequestion") {
        history.replace({ pathname: "/" });
        history.push(`/prequestion/${cid}`);
      } else if (next !== undefined) {
        history.replace({ pathname: "/" });
        history.push(`/en/${next}`);
      }
    }
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  resetState() {
    this.setState({
      isLoginPage: history.location.pathname === "/en/login",
      isSignupPage: history.location.pathname === "/en/signup",
      checkedAccountExist: false,
      isLoading: false,
      errorMsg: undefined,
    });
  }

  _logPageVisit = async () => {
    // const {log_sent} = this.state;
    // if (log_sent) {
    //     return;
    // }
    // this.setState({
    //     log_sent: true,
    // });
    const eventID = createEventId();
    await this._sendLog("pageview_login", eventID);
  };

  _logSocialLogin = async () => {
    const eventID = createEventId();
    await this._sendLog("login_kko", eventID);
  };

  _logEmailLogin = async () => {
    const eventID = createEventId();
    await this._sendLog("login_email", eventID);
  };

  onNextClicked = async (values) => {
    this._userTracking();
    const { checkedAccountExist, isSignupPage, isLoginPage } = this.state;
    const { cid } = params();
    const next = "classroom"; // 로그인 페이지에서 무조건 classroom으로 가도록 고정
    if (isLoginPage && !checkedAccountExist) {
      // 가입 여부 확인
      let { email } = values;
      let requestOptions = {
        method: "GET",
      };
      // email = email.toLowerCase();
      this.setState({ isLoading: true });
      fetch(`${config.apiUrl}/v1/auth/login?email=${email}`, requestOptions)
        .then(handleResponse)
        .then((data) => {
          this.setState({ isLoading: false });
          if (data.is_exist) {
            this.setState({
              checkedAccountExist: true,
            });
          } else {
            history.push("/en/signup");
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
    if (isLoginPage && checkedAccountExist) {
      // 로그인
      this.setState({ isLoading: true });
      const { email, password } = values;
      const { crm, maker, reviews } = params();
      this._logEmailLogin();
      this.props.login(
        email,
        password,
        cid,
        false,
        next,
        crm,
        maker,
        reviews,
        true
      );
    }
    if (isSignupPage) {
      // 회원가입
      this.setState({ isLoading: true });
      const { email, password, name, school, region } = values;
      const country_code = "+62"; // indonesia로 고정
      const phone = ""; // 연락처 받지 않음
      const eventID = createEventId();
      const en = true;
      const address = "LGI"; // address는 LGI로 고정

      await sendLog("complete_registration");
      await this._sendLog("signup", eventID);
      this.props.signup({
        name,
        email,
        password,
        phone,
        cid,
        country_code,
        next,
        school,
        region,
        en,
        address,
      });
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  fillHyphen = (e) => {
    let phone = e.target.value;
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    this.formRef.current.setFieldsValue({
      phone: phone,
    });
  };

  _userTracking = () => {
    const isPurchasing = this.props.history.location.search.includes("cid");
    if (isPurchasing) {
      if (this.props.course.loaded === false) {
        return;
      }
    }
  };

  getCoMktName = () => {
    const { comkt } = this.state;
    if (comkt === undefined) {
      return undefined;
    }
    switch (comkt) {
      case "miniintern":
        return "미니인턴";
      case "hakdokman":
        return "학독만";
    }
  };

  _changeTrim = (e) => {
    return e.target.value.trim();
  };

  _changePhone = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value[0] === "+") {
      this.formRef.current.setFieldsValue({
        phone: e.target.value,
      });
    } else {
      return this.fillHyphen(e);
    }
  };

  loginWithKakao = (auto = false) => {
    const baseUrl = `${config.apiUrl}/${API_VERSION}`;
    const redirectUri = `${baseUrl}/oauth/kakao/login`;
    if (!auto) {
      this._logSocialLogin();
    }
    let state = params();
    let settings = state
      ? { redirectUri, state: JSON.stringify(state) }
      : { redirectUri };
    let Kakao = window.Kakao;
    if (!window.location.href.includes("https://online.spartacodingclub.kr")) {
      alert(settings);
      alert(JSON.stringify(settings));
    }
    Kakao.Auth.authorize(settings);
  };

  _showFirstDiscount = (isShow) => {
    this.setState({
      firstDiscount: isShow,
    });
  };

  render() {
    const { next, fdon, logina } = params();
    const { checkPlatform } = window;
    const deviceType = checkPlatform();
    const { user, loggingIn, todaydiscount } = this.props;
    const {
      isLoginPage,
      isSignupPage,
      checkedAccountExist,
      isLoading,
      errorMsg,
      emailShow,
      detailShow,
    } = this.state;
    const isPurchasing = this.props.history.location.search.includes("cid");
    const buttonText = "Next";
    const login_bg_path = "/assets/images/login/login_bg.jpg";

    const comkt_name = this.getCoMktName();
    return (
      <div className={cx("wrapper")}>
        <div
          className={
            isSignupPage ? cx("pageContainer", "signup") : cx("pageContainer")
          }
        >
          <div className={cx("leftContainer")}>
            <div className={cx("leftContentWrapper", "border")}>
              <div className={cx("contentWrapper")}>
                <h3>
                  Welcome to <br /> SpartaCodingClub 😎
                </h3>
                <div className={cx("formWrapper")}>
                  <Form
                    name="login"
                    onFinish={this.onNextClicked}
                    onFinishFailed={this.onFinishFailed}
                    validateMessages={validateMessages}
                    ref={this.formRef}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      onChange={this._changeTrim}
                      getValueFromEvent={this._changeTrim}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          type: "email",
                        },
                      ]}
                    >
                      <Input
                        autoComplete="new-password"
                        onChange={() =>
                          this.setState({ checkedAccountExist: false })
                        }
                      />
                    </Form.Item>

                    {(checkedAccountExist || isSignupPage) && (
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  validateMessages.required
                                );
                              }
                              if (value.length < 6) {
                                return Promise.reject(
                                  validateMessages.types.password
                                );
                              }
                              if (/[a-z]/.test(value) === false) {
                                return Promise.reject(
                                  validateMessages.types.password
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        onChange={this._changeTrim}
                        getValueFromEvent={this._changeTrim}
                      >
                        <Input.Password
                          autoComplete="new-password"
                          onChange={() =>
                            this.setState({ errorMsg: undefined })
                          }
                        />
                      </Form.Item>
                    )}

                    {/* 연락처 삭제 */}
                    {/*{(isSignupPage) && (*/}
                    {/*    <Fragment>*/}
                    {/*        <Form.Item*/}
                    {/*            label="Phone"*/}
                    {/*            name="phone"*/}
                    {/*            rules={[*/}
                    {/*                ({getFieldValue}) => ({*/}
                    {/*                    validator(_, value) {*/}
                    {/*                        if (!value) {*/}
                    {/*                            return Promise.reject(validateMessages.required)*/}
                    {/*                        }*/}
                    {/*                        if (!value.match(/^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/)*/}
                    {/*                            &&*/}
                    {/*                            !value.match(/^[\+][0-9]{1,5}[-][0-9]{6,20}$/im)*/}
                    {/*                        ) {*/}
                    {/*                            return Promise.reject(validateMessages.types.phone)*/}
                    {/*                        }*/}
                    {/*                        return Promise.resolve()*/}
                    {/*                    }*/}
                    {/*                })*/}
                    {/*            ]}*/}

                    {/*            onChange={this._changePhone}*/}
                    {/*            getValueFromEvent={this._changePhone}*/}
                    {/*        >*/}
                    {/*            <Input*/}
                    {/*                // onChange={e => this.fillHyphen(e)}*/}
                    {/*            />*/}
                    {/*        </Form.Item>*/}
                    {/*        <span style={{fontSize: 13}}>* Overseas customers must fill out the country code according to the form.<br/>*/}
                    {/*        ex) +1-1231231234</span>*/}
                    {/*    </Fragment>*/}
                    {/*)}*/}

                    {isSignupPage && (
                      <Form.Item
                        label="Full Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}

                    {isSignupPage && (
                      <Form.Item
                        label="Name of School"
                        name="school"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}

                    {/* select */}
                    {isSignupPage && (
                      <Form.Item
                        name="region"
                        label="Region"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a region"
                          onChange={() => {}}
                          allowClear
                        >
                          <Option value="Jakarta">Jakarta</Option>
                          <Option value="Bogor">Bogor</Option>
                          <Option value="Depok">Depok</Option>
                          <Option value="Tangerang">Tangerang</Option>
                          <Option value="Bekasi">Bekasi</Option>
                          <Option value="Other">Other</Option>
                        </Select>
                      </Form.Item>
                    )}

                    {errorMsg && <p className={cx("errorMsg")}>{errorMsg}</p>}

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {isLoading ? "In progress..." : buttonText}
                      </Button>
                    </Form.Item>

                    {isSignupPage && (
                      <p className={cx("signup-tos")}>
                        By signing up, you agree to our Terms of Service and
                        Privacy Policy.
                      </p>
                    )}

                    {/*{(!checkedAccountExist && isLoginPage) &&*/}
                    {/*<Row className={cx('accountEditLinkWrapper')}>*/}
                    {/*    <Link*/}
                    {/*        to={`/accounts/read`}>*/}
                    {/*        I can't remember the e-mail.*/}
                    {/*    </Link>*/}
                    {/*</Row>*/}
                    {/*}*/}

                    {/*{(checkedAccountExist && !isSignupPage) &&*/}
                    {/*<Row className={cx('accountEditLinkWrapper')}>*/}
                    {/*    <Link*/}
                    {/*        to={`/accounts/edit?email=${this.formRef.current.getFieldValue('email')}`}>*/}
                    {/*        Did you forget your password?*/}
                    {/*    </Link>*/}
                    {/*</Row>*/}
                    {/*}*/}
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx("rightContainer")}
            style={{ "--bg-image": `url(${login_bg_path})` }}
          ></div>
        </div>
        <Modal
          title="Already logged in."
          visible={!loggingIn && user != undefined && next == undefined}
          closable={false}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => (window.location.href = "/en/classroom")}
            >
              OK
            </Button>,
          ]}
        ></Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { course, todaydiscount, environment, paymentInfo } = state;
  const { loggingIn, user, isFetching, error } = state.authentication;
  return {
    loggingIn,
    user,
    isFetching,
    error,
    course,
    todaydiscount,
    environment,
  };
}

const connectedLoginPageEn = connect(mapStateToProps, {
  login: userActions.login,
  signup: userActions.signup,
  getCourse: courseActions.getCourse,
  getTodaydiscount: todaydiscountActions.getTodaydiscount,
  _initEnvironment: initEnvironment,
})(LoginPageEn);
export { connectedLoginPageEn as LoginPageEn };
