import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import privateInstance from "../../../v2/_helper/axios";

const useGetOrderStatus = (orderNo, isProcessing) =>
  useQuery({
    queryKey: ["orderStatus", orderNo],
    queryFn: () =>
      privateInstance.get(`/orders/order-status?orderNo=${orderNo}`, {
        skipDuplicatedCheck: true,
      }),
    refetchInterval: isProcessing ? 1000 : false,
    enabled: !!orderNo && isProcessing,
  });

export const usePortOneValidation = (orderNo, courseId, roundId) => {
  const [isProcessing, setIsProcessing] = useState(true);
  const { data, isSuccess, isError } = useGetOrderStatus(orderNo, isProcessing);
  const orderStatus = data?.data;

  if (isError) {
    alert("결제 처리에 실패하였습니다.");
    window.location.href = `/payment/${courseId}${
      roundId ? `/${roundId}` : ""
    }`;
  }

  useEffect(() => {
    if (orderStatus?.status === "paid") {
      setIsProcessing(false);
    }
  }, [orderStatus]);

  useEffect(() => {
    if (isSuccess && orderStatus?.status === "paid") {
      if (orderStatus.doneUrl.includes("http")) {
        window.location.href = orderStatus.doneUrl;
      } else {
        window.location.href = `${process.env.REACT_APP_ONLINE_URL}/${orderStatus.doneUrl}`;
      }
    }
  }, [isSuccess, orderStatus]);
};

export const usePortOneMobileValidation = (courseId, roundId) => {
  const searchParams = new URLSearchParams(window.location.search);
  const errorCode = searchParams.get("error_code");
  const errorMsg = searchParams.get("error_msg");

  useEffect(() => {
    const handleError = () => {
      if (errorCode || errorMsg) {
        alert(
          `결제에 실패하였습니다.\n실패 사유는 다음과 같습니다 : ${errorMsg}`
        );
        window.location.href = `/payment/${courseId}${
          roundId ? `/${roundId}` : ""
        }`;
      }
    };
    handleError();
  }, [courseId, errorCode, errorMsg, roundId]);
};
