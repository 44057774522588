import React, { Component, Fragment } from "react";
import { history } from "../../_helpers";
import { config } from "../../config";
import { Button, Checkbox, Col, Modal, Row, Skeleton } from "antd";
import classNames from "classnames/bind";
import styles from "./PaymenPage_B.module.scss";
import { connect } from "react-redux";
import {
  getGETAuthOptions,
  getGETOptions,
  getPostAuthOptions,
  getStartPaymentNoLoginUrl,
} from "../../_urls";
import {
  paymentActions,
  priceByNumberActions,
  todaydiscountActions,
  userActions,
} from "../../_actions";
import { FooterV2 } from "../../_components/Footer/FooterV2";

let is_discounted = false;
const cx = classNames.bind(styles);

class PaymentPage_B extends Component {
  constructor(props) {
    super(props);
    let paramsDict = { payMethod: "CARD" };
    let paramsString = history.location.search;
    if (paramsString.length > 0) {
      let params = paramsString.split("?")[1].split("&");
      for (const p of params) {
        let part = p.split("=");
        paramsDict[part[0]] = part[1];
      }
    }
    this.state = {
      ...paramsDict,
      price: 0,
      card: true,
      kakaopay: false,
      paypal: false,
      NicepayReserved: "",
      DirectShowOpt: "",
      todaydiscount_discount: 0,
      coupon_discount: 0,
      name: "",
      phone: "",
      email: "",
      coupon_applied: false,
      moid: "",
      EdiDate: "",
      MID: "",
      EncryptData: "",
      ReturnURL: "",
      TID: "",
      isForeign: false,
      isByEnrolleds: true,

      logNationUrl:
        "https://pro.ip-api.com/json/?fields=status,message,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,currency,isp,org,as,mobile,query&key=FVaMw6OLSQKC0LD",

      count: 0,
      counter: null,
      timerMessage: "",
      isFirstDiscount: false,

      fullRefund: false,
      EXCHANGE_RATIO: 1100,
    };
  }

  _testinitialsetforpaypal = () => {
    window.payment_method = "paypal";
    this.setState({
      card: false,
      kakaopay: false,
      paypal: true,
      isForeign: true,
    });
  };

  async componentDidMount() {
    this.initCounter();
    this._testinitialsetforpaypal();
    window.scrollTo(0, 0);
    const { err } = this.state;
    const { cid } = this.state;
    if (err !== undefined) {
      alert(`결제에 실패하였습니다.\n\n${decodeURI(err)}`);
    }

    this._logNation();

    if (this.props.user !== undefined) {
      this.setState({
        name: this.props.user.name,
        email: this.props.user.email,
      });
      this.fillHyphen(this.props.user.phone);
    }

    if (this.props.location.pathname.includes("fullrefund")) {
      this.setState({
        fullRefund: true,
      });
    } else {
      this.setState({
        fullRefund: false,
      });
    }

    this.props.getTodaydiscount(cid);
    await this.props.getPaymentInfo(cid);
    this.props.getPriceByNumber(cid);
    fetch(`${config.apiUrl}/v1/accounts/enrolled/${cid}`, getGETAuthOptions())
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          alert("이미 신청하신 강의입니다!\n내 강의실로 이동합니다.");
          window.location.href = "/classroom";
        }
      });
  }

  _logNation = () => {
    const { logNationUrl } = this.state;
    const options = getGETOptions();
    fetch(logNationUrl, options)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.country !== "South Korea") {
          window.payment_method = "paypal";
          this.setState({
            isForeign: true,
            card: false,
            kakaopay: false,
            paypal: true,
          });
        }

        // data['page'] = 'checkout';
        // data['course_id'] = this.state.cid;
        // const endpoint = getApiLogUrl();
        // const logOptions = getPostOptions(data);
        // return fetch(endpoint, logOptions);
      })
      .catch((error) => {});
  };

  _numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  _startPayment = () => {
    const { paypal, isFirstDiscount, fullRefund } = this.state;
    const { nicepayStart, nicepayPayPal } = window;
    const course_id = this.state.cid;
    const { name, phone, email, isByEnrolleds } = this.state;

    if (name === "") {
      alert("이름을 입력해주세요.");
      document.getElementById("name_input").focus();
      return;
    }
    if (this.validateEmail(email) === false) {
      alert("올바른 이메일을 입력해주세요.");
      document.getElementById("email_input").focus();
      return;
    }
    if (this.validatePhone(phone) === false) {
      alert("올바른 연락처를 입력해주세요.");
      document.getElementById("phone_input").focus();
      return;
    }

    const data = {
      name: name.trim(),
      phone: phone.split("-").join(""),
      email: email.trim().toLowerCase(),
      promo_code: document
        .getElementById("promo_code")
        .value.toLowerCase()
        .split("-")
        .join(""),
      course_id: course_id,
      rcmd_phone: "",
      payment_method: window.payment_method,
      isPaypal: window.payment_method === "paypal",
      isByEnrolleds: isByEnrolleds,
      isFirstDiscount: isFirstDiscount,
      fullRefund: fullRefund,
    };

    if (
      is_discounted === false &&
      document.getElementById("promo_code").value !== ""
    ) {
      alert("할인코드를 적용해주세요!");
      document.getElementById("promo_code").focus();
      return;
    }

    let options = getPostAuthOptions(data);
    let endpoint = getStartPaymentNoLoginUrl(course_id);
    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.message !== undefined) {
          if (window.confirm(result.message)) {
            // window.location.href = `/login?cid=${this.state.cid}`;
          } else {
            alert("결제가 중지되었습니다. 다시 시도해주세요.");
            document.getElementById("email_input").focus();
          }
          return;
        }

        const { nicepayinfo, order_no, TID, price } = result;
        if (nicepayinfo === undefined) {
          this.setState({
            alert_msg_title: "오류가 발생하였습니다.",
            modalVisible: true,
            alert_msg: "오류가 발생하였습니다. 재시도하시기 바랍니다.",
          });
          return;
        }
        const { EdiDate, MID, EncryptData, ReturnURL } = nicepayinfo;

        this.setState({
          moid: order_no,
          EdiDate,
          MID,
          price,
          EncryptData,
          ReturnURL,
          TID,
        });

        if (paypal) {
          nicepayPayPal();
        } else {
          nicepayStart();
        }
      });
  };

  _setMethod = (e) => {
    window.payment_method = e.target.value;
    if (e.target.value === "kakaopay") {
      this.setState({
        card: false,
        kakaopay: true,
        paypal: false,
        NicepayReserved: "DirectKakao=Y",
        DirectShowOpt: "CARD",
      });
    } else if (e.target.value === "CARD") {
      this.setState({
        card: true,
        kakaopay: false,
        paypal: false,
        NicepayReserved: "",
        DirectShowOpt: "",
      });
    } else if (e.target.value === "paypal") {
      this.setState({
        card: false,
        kakaopay: false,
        paypal: true,
        NicepayReserved: "",
        DirectShowOpt: "",
      });
    }
  };

  _applyPromo = (e) => {
    e.preventDefault();
    let promo_code = document.getElementById("promo_code").value;
    if (promo_code === "") {
      alert("올바른 할인코드를 입력하세요.");
      document.getElementById("promo_code").value = "";
      return;
    }

    promo_code = promo_code.toLowerCase().split("-").join("");

    fetch(`${config.apiUrl}/v1/coupons/${promo_code}`)
      .then((resp) => resp.json())
      .then((result) => {
        let discount = parseInt(result["discount"]);
        if (discount === -1) {
          alert("올바른 할인코드를 입력하세요.");
          document.getElementById("promo_code").focus();
          document.getElementById("promo_code").value = "";
          return;
        }
        is_discounted = true;

        alert("할인코드 적용 완료! 😎");
        if (promo_code === "bkbktest") {
          this.props.setPaymentType(true);
        }
        this.setState({
          coupon_discount: discount,
          coupon_applied: true,
        });
      });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handlePhoneChange = (e) => {
    this.fillHyphen(e.target.value);
  };

  fillHyphen = (phone) => {
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    this.setState({
      phone,
    });
  };

  validatePhone = (phone) => {
    const re = /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/;
    return re.test(phone);
  };

  handleEmailChange = (e) => {
    e.target.value = e.target.value.trim().toLowerCase();
    this.setState({
      email: e.target.value,
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  initCounter = () => {
    let count = window.localStorage.getItem("count");
    if (count === null || count > 0) {
      this.setState({
        count: this.getLocalStorage("count") || 1800,
        // count:  10,
        counter: setInterval(this.timer, 1000),
      });
    }
  };
  getLocalStorage = (key) => {
    return window.localStorage.getItem(key);
  };

  setLocalStorage = (key, val) => {
    if (window.localStorage) {
      window.localStorage.setItem(key, val);
    }
    return val;
  };

  timer = () => {
    const { count, counter } = this.state;
    if (count <= -1) {
      this.setState({
        count: this.setLocalStorage("count", count - 1),
        isFirstDiscount: false,
      });
      clearInterval(counter);
      return;
    }
    this.setState({
      count: this.setLocalStorage("count", count - 1),
      isFirstDiscount: true,
    });

    let seconds = count % 60;
    let minutes = Math.floor(count / 60);
    let hours = Math.floor(minutes / 60);
    minutes %= 60;

    this.setState({
      timerMessage: `${minutes}분 ${seconds}초 남음`,
    });
  };

  render() {
    let {
      alert_msg_title,
      modalVisible,
      alert_msg,
      coupon_discount,
      coupon_applied,
      name,
      phone,
      email,
      paypal,
      isForeign,
      timerMessage,
      count,
      isFirstDiscount,
      fullRefund,
      EXCHANGE_RATIO,
    } = this.state;

    let { todaydiscount, paymentInfo, priceByNumber } = this.props;
    if (
      paymentInfo.loaded === false ||
      todaydiscount.loaded === false ||
      priceByNumber.loaded === false
    ) {
      return <Skeleton />;
    }
    const { price_earlybird } = priceByNumber.data;

    todaydiscount = todaydiscount.data;
    paymentInfo = paymentInfo.data;

    let { display_price, price, reimburse, title, round, period_title } =
      paymentInfo;
    const todaydiscount_discount = todaydiscount.is_activated
      ? todaydiscount.discount
      : 0;
    let final_price = price - coupon_discount - todaydiscount_discount;
    let diff = (parseInt(display_price) - parseInt(price)).toString();

    // 인원 할인 로직
    diff = (
      parseInt(display_price) - parseInt(price_earlybird.price)
    ).toString();
    final_price =
      price_earlybird.price - coupon_discount - todaydiscount_discount;
    if (isFirstDiscount) {
      final_price -= 20000;
    }

    if (paypal) {
      // 결제수단 페이팔을 선택한 경우
      // let {price_paypal} = paymentInfo;

      let { price_paypal } = priceByNumber.data;
      display_price = Math.ceil((500000 / EXCHANGE_RATIO) * 100);
      diff = display_price - price_paypal["price"];
      coupon_discount = Math.ceil((coupon_discount / EXCHANGE_RATIO) * 100);
      final_price = price_paypal["price"] - coupon_discount;
      if (isFirstDiscount) {
        final_price -= Math.ceil((20000 / EXCHANGE_RATIO) * 100);
      }
      reimburse = Math.ceil((reimburse / EXCHANGE_RATIO) * 100);
    }

    return (
      <div>
        <div className={cx("backgroundWrapper")}>
          <div className={cx("contentWrapper")}>
            {isFirstDiscount === false && (
              <div className={cx("headerWrapper")}>
                <h3>결제하기</h3>
              </div>
            )}

            {isFirstDiscount && (
              <div className={cx("timer")}>
                <div className={cx("timerFirstRow")}>
                  <div className={cx("timerTitle")}>첫 방문 할인!</div>
                  <div className={cx("timerClock")}>
                    <img src={"/assets/images/payment/clock.png"} alt="" />
                    <span>{timerMessage}</span>
                  </div>
                </div>
                <div className={cx("timerSecondRow")}>
                  지금 바로 결제하고, 최저가에 들으세요.
                </div>
              </div>
            )}

            {/*<div className={cx("todaydiscount-top")}>*/}
            {/*    {todaydiscount.total_amount}!<br/>*/}
            {/*    지금 신청하고 코딩의 세계로 함께 떠나볼까요?*/}
            {/*</div>*/}

            {todaydiscount && todaydiscount.is_activated && (
              <div className={cx("todaydiscount-top")}>
                오늘의 한정 할인({todaydiscount.total_amount}자리) 진행 중!
                <br />
                당신을 위한 한 자리를 마련했어요.
              </div>
            )}
            <div className={cx("formWrapper")}>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>주문 정보</label>
                </div>
                <div className={cx("content")}>
                  <div
                    id="course-title"
                    // className={cx('course-title')}>{title + ' - ' + round['round']} <span className={cx('urgentContainer')}>마감임박</span></div>
                    className={cx("course-title")}
                  >
                    {title + " - " + round["round"]}
                  </div>
                  <div id="course-date" className={cx("course-date")}>
                    {round["start_date"] + " ~ " + round["end_date"]}
                  </div>
                </div>
              </div>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>연락처 정보</label>
                </div>
                <div className={cx("content", "userinfoContainer")}>
                  <div className={cx("info-label")}>이름</div>
                  <div className={cx("info-detail")}>
                    <input
                      id="name_input"
                      type="text"
                      placeholder={"이름을 입력해주세요."}
                      className={cx("userInfo")}
                      value={name}
                      onChange={this.handleNameChange}
                    />
                  </div>
                  <div className={cx("info-label")}>연락처</div>
                  <div className={cx("info-detail")}>
                    {/*{user_phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}*/}
                    <input
                      id="phone_input"
                      type="text"
                      placeholder={"010-1234-5678"}
                      className={cx("userInfo")}
                      value={phone}
                      onChange={this.handlePhoneChange}
                    />
                  </div>
                  <div className={cx("info-label")}>이메일</div>
                  <div className={cx("info-detail")}>
                    <input
                      id="email_input"
                      type="text"
                      placeholder={"example@example.com"}
                      className={cx("userInfo")}
                      value={email}
                      onChange={this.handleEmailChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>결제 금액</label>
                </div>
                <div className={cx("content")}>
                  <div className={cx("featureContent")}>
                    {fullRefund && (
                      <div className={cx("feature")}>
                        <img src={"/assets/icons/coin_small.png"} alt="" />
                        <span>개강 7일 이내 100% 환불 보장</span>
                      </div>
                    )}
                    <div className={cx("feature")}>
                      <img src={"/assets/icons/coin_small.png"} alt="" />
                      <span>완주 시 5만 원 환급</span>
                    </div>
                  </div>
                  <table>
                    <tbody>
                      {paypal === false && (
                        <tr>
                          <td>정가</td>
                          <td
                            id="display-price"
                            className={cx("display-price")}
                          >{`${this._numberWithCommas(display_price)}원`}</td>
                        </tr>
                      )}
                      {paypal && (
                        <tr>
                          <td>정가</td>
                          <td
                            id="display-price"
                            className={cx("display-price")}
                          >{`${this._numberWithCommas(
                            display_price / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {/*{paypal === false &&*/}
                      {/*<tr className={cx('discountContainer')}>*/}
                      {/*    <td id="price-period-title">{period_title === '정가' ? '할인' : period_title}</td>*/}
                      {/*    <td id="display-discount">{`- ${this._numberWithCommas(diff)}원`}</td>*/}
                      {/*</tr>*/}
                      {/*}*/}
                      {paypal === false && (
                        <tr className={cx("discountContainer")}>
                          <td id="price-period-title">
                            {price_earlybird.title} 할인
                          </td>
                          <td id="display-discount">{`- ${this._numberWithCommas(
                            diff
                          )}원`}</td>
                        </tr>
                      )}
                      {paypal && (
                        <tr className={cx("discountContainer")}>
                          <td id="price-period-title">
                            {price_earlybird.title} 할인
                          </td>
                          <td id="display-discount">{`- ${this._numberWithCommas(
                            diff / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {paypal === false && coupon_applied && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>할인 코드 금액</td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(
                            coupon_discount
                          )}원`}</td>
                        </tr>
                      )}
                      {paypal && coupon_applied && (
                        <tr
                          className={cx("couponContainer")}
                          id="discount-show"
                        >
                          <td>할인 코드 금액</td>
                          <td
                            id="display-coupon"
                            className={cx("coupon-highlight")}
                          >{`- ${this._numberWithCommas(
                            coupon_discount / 100
                          )}달러`}</td>
                        </tr>
                      )}
                      {paypal && isFirstDiscount && (
                        <tr>
                          <td className={cx("highlight-text")}>첫 방문 할인</td>
                          <td
                            className={cx("highlight-text")}
                            id="price-with-reimburse"
                          >
                            {`${this._numberWithCommas(
                              Math.ceil((20000 / EXCHANGE_RATIO) * 100) / 100
                            )}달러`}
                          </td>
                        </tr>
                      )}
                      {paypal === false && isFirstDiscount && (
                        <tr>
                          <td className={cx("highlight-text")}>첫 방문 할인</td>
                          <td
                            className={cx("highlight-text")}
                            id="price-with-reimburse"
                          >
                            {`${this._numberWithCommas(20000)}원`}
                          </td>
                        </tr>
                      )}
                      {paypal === false &&
                        todaydiscount &&
                        todaydiscount.is_activated && (
                          <tr
                            id="todaydiscountContainer"
                            className={cx("todaydiscountContainer")}
                          >
                            <td id="todaydiscount-title">
                              오늘의 한정 할인({todaydiscount.total_amount}자리)
                            </td>
                            <td id="todaydiscount-discount">
                              - {this._numberWithCommas(todaydiscount_discount)}
                              원
                            </td>
                          </tr>
                        )}
                      {paypal === false && (
                        <tr className={cx("finalContainer")}>
                          <td className={cx("final")}>결제 가격</td>
                          <td
                            className={cx("final")}
                            id="price-discount-before"
                          >
                            {`${this._numberWithCommas(final_price)}원`}
                          </td>
                        </tr>
                      )}
                      {paypal && (
                        <tr className={cx("")}>
                          <td className={cx("final")}>결제 가격</td>
                          <td
                            className={cx("final")}
                            id="price-discount-before"
                          >
                            {final_price / 100}달러
                          </td>
                        </tr>
                      )}
                      {/*<tr>*/}
                      {/*    <td className={cx('highlight-text')}>완주시 환급금</td>*/}
                      {/*    <td className={cx('highlight-text')}*/}
                      {/*        id="price-with-reimburse">{`${this._numberWithCommas(reimburse)}원`}*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      <tr className={cx("divideContainer")}>
                        <td>완주시 6개월 할부액</td>
                        {paypal === false && (
                          <td id="divided-price" className={cx("dividedPrice")}>
                            {`월 ${this._numberWithCommas(
                              parseInt((final_price - reimburse) / 6)
                            )}원`}
                          </td>
                        )}
                        {paypal && (
                          <td id="divided-price" className={cx("dividedPrice")}>
                            {`월 ${this._numberWithCommas(
                              Math.ceil((final_price - reimburse) / 100 / 6)
                            )}달러`}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  {/*{paypal === false &&*/}
                  <Fragment>
                    <table>
                      <tbody>
                        <tr>
                          <td className={cx("promo-label")}>할인 코드</td>
                          <td className={cx("promo-input")}>
                            <div className={cx("inputContainer")}>
                              <input
                                className={cx("myInput")}
                                type="text"
                                id="promo_code"
                                placeholder="할인코드/추천인 번호 입력"
                              />
                              <a
                                className={cx("inputImg")}
                                href="#"
                                onClick={this._applyPromo}
                              >
                                적용
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td className={cx("promo-basic")}>
                            *할인코드가 없다구요? LOVESPARTA를 입력해보세요!
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Fragment>
                  {/*}*/}
                </div>
              </div>
              <div>
                <div className={cx("titletWrapper")}>
                  <label>결제 방식</label>
                </div>
                <div className={cx("content")}>
                  <Row
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {isForeign && (
                      <Fragment>
                        <Col span={8}>
                          <Checkbox
                            onClick={this._setMethod}
                            checked={this.state.paypal}
                            id="paypal"
                            value="paypal"
                          >
                            페이팔
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            onClick={this._setMethod}
                            checked={this.state.card}
                            id="payment_card"
                            value="CARD"
                          >
                            신용카드
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            onClick={this._setMethod}
                            checked={this.state.kakaopay}
                            id="payment_kakaopay"
                            value="kakaopay"
                          >
                            카카오페이
                          </Checkbox>
                        </Col>
                      </Fragment>
                    )}
                    {!isForeign && (
                      <Fragment>
                        <Col span={12}>
                          <Checkbox
                            onClick={this._setMethod}
                            checked={this.state.card}
                            id="payment_card"
                            value="CARD"
                          >
                            신용카드
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            onClick={this._setMethod}
                            checked={this.state.kakaopay}
                            id="payment_kakaopay"
                            value="kakaopay"
                          >
                            카카오페이
                          </Checkbox>
                        </Col>
                      </Fragment>
                    )}
                  </Row>
                </div>
                <div className={cx("bottomForm")}>
                  <div className={cx("privacy-form")}>
                    <a
                      className={cx("privacy")}
                      href="https://www.notion.so/spartacodingclub/cb0c63c1145f4b60aacf0692a888e6fd"
                      target="_blank"
                    >
                      개인정보수집,
                    </a>
                    <a
                      className={cx("privacy")}
                      href="https://www.nicepay.co.kr/cs/terms/policy1.do"
                      target="_blank"
                    >
                      전자금융거래 기본약관,
                    </a>
                    <br></br>
                    <a
                      className={cx("privacy")}
                      href="https://www.nicepay.co.kr/cs/terms/private.do"
                      target="_blank"
                    >
                      개인정보처리방침에 동의하고,
                    </a>
                    <br></br>
                  </div>

                  {/*{ ?*/}
                  {/*    <Tooltip*/}
                  {/*        overlayStyle={{position: 'fixed', fontSize: 12, width: 340}}*/}
                  {/*        placement={"bottom"}*/}
                  {/*        title={`지금, ${user_name}님을 위한 특가 한자리를 비워뒀어요.`}*/}
                  {/*        visible={todaydiscount && todaydiscount.is_activated}>*/}
                  {/*        <Button type="primary" htmlType="submit" onClick={this._startPayment}>*/}
                  {/*            결제하기*/}
                  {/*        </Button>*/}
                  {/*    </Tooltip>*/}
                  {/*    :*/}
                  {/*    <Button type="primary" htmlType="submit" onClick={this._startPayment}>*/}
                  {/*        결제하기*/}
                  {/*    </Button>*/}
                  {/*}*/}

                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={this._startPayment}
                  >
                    결제하기
                  </Button>
                  {todaydiscount && todaydiscount.is_activated && (
                    <div className={cx("todaydiscount-tooltip")}>
                      {`지금, 당신을 위한 한 자리를 가져가세요.👆`}
                    </div>
                  )}
                </div>
              </div>
              <form
                name="payForm"
                method="post"
                action={`${config.apiUrl}/v1/payment/authReq`}
              >
                <input type="hidden" id="name" name="BuyerName" value={name} />
                <input
                  type="hidden"
                  id="phone"
                  name="BuyerTel"
                  value={phone.split("-").join("")}
                />
                <input
                  type="hidden"
                  id="email"
                  name="BuyerEmail"
                  value={email}
                />
                <input
                  type="hidden"
                  id="GoodsName"
                  name="GoodsName"
                  defaultValue={title}
                />
                <input
                  type="hidden"
                  id="price"
                  name="Amt"
                  value={final_price}
                />
                <input
                  type="hidden"
                  id="course_id"
                  name="course_id"
                  defaultValue={this.state.cid}
                />
                <input
                  type="hidden"
                  id="PayMethod"
                  name="PayMethod"
                  value="CARD"
                />
                <input
                  type="hidden"
                  id="moid"
                  name="Moid"
                  value={this.state.moid}
                />
                <input
                  type="hidden"
                  id="ReturnURL"
                  name="ReturnURL"
                  value={this.state.ReturnURL}
                />
                <input
                  type="hidden"
                  id="MID"
                  name="MID"
                  value={this.state.MID}
                />
                <input type="hidden" name="VbankExpDate" defaultValue="" />
                <input type="hidden" name="NpLang" defaultValue="KO" />
                <input type="hidden" name="GoodsCl" defaultValue="1" />
                <input type="hidden" name="TransType" defaultValue="0" />
                <input type="hidden" name="CharSet" defaultValue="utf-8" />
                <input type="hidden" name="ReqReserved" defaultValue="" />
                <input
                  type="hidden"
                  id="EdiDate"
                  name="EdiDate"
                  value={this.state.EdiDate}
                />
                <input
                  type="hidden"
                  id="SignData"
                  name="SignData"
                  value={this.state.EncryptData}
                />
                <input
                  id="NicepayReserved"
                  type="hidden"
                  name="NicepayReserved"
                  value={this.state.NicepayReserved}
                />
                <input
                  id="DirectShowOpt"
                  type="hidden"
                  name="DirectShowOpt"
                  value={this.state.DirectShowOpt}
                />
              </form>

              <form
                name="payForm_paypal"
                method="post"
                style={{ display: "none" }}
                action="https://webapi.nicepay.co.kr/webapi/paypal/order.jsp"
              >
                <input
                  style={{ width: 550 }}
                  name="MID"
                  value={this.state.MID}
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="TID"
                  value={this.state.TID}
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="Moid"
                  value={this.state.moid}
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="EdiDate"
                  value={this.state.EdiDate}
                />
                <br />
                <input
                  style={{ width: 550 }}
                  name="Amt"
                  value={final_price}
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="GoodsName"
                  defaultValue={title}
                />{" "}
                <br />
                <input style={{ width: 550 }} name="Quantity" value="1" />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="BuyerName"
                  value={this.state.name}
                />
                <br />
                <input
                  style={{ width: 550 }}
                  name="BuyerEmail"
                  value={this.state.email}
                />
                <br />
                <input
                  style={{ width: 550 }}
                  name="ReturnURL"
                  value={this.state.ReturnURL}
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="CharSet"
                  value="euc-kr"
                />{" "}
                <br />
                <input
                  style={{ width: 550 }}
                  name="Currency"
                  value="USD"
                />{" "}
                <br />
                En:{" "}
                <input
                  style={{ width: 550 }}
                  name="SignData"
                  value={this.state.EncryptData}
                />{" "}
                <br />
              </form>
            </div>
          </div>
        </div>
        <FooterV2 />
        <Modal
          title={alert_msg_title}
          visible={modalVisible}
          closable={true}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => this.setState({ modalVisible: false })}
            >
              확인
            </Button>,
          ]}
        >
          <p>{alert_msg}</p>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, todaydiscount, paymentInfo, priceByNumber } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    todaydiscount,
    paymentInfo,
    priceByNumber,
  };
}

const connectedPaymentPage = connect(mapStateToProps, {
  setPaymentType: paymentActions.setPaymentType,
  getTodaydiscount: todaydiscountActions.getTodaydiscount,
  getPaymentInfo: paymentActions.getPaymentInfo,
  login: userActions.login,
  getPriceByNumber: priceByNumberActions.getPriceByNumber,
})(PaymentPage_B);
export { connectedPaymentPage as PaymentPage_B };
