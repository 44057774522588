import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./AccountReadPage.module.scss";
import classNames from "classnames/bind";
import { NavContainer } from "../../_containers";
import { Button, Form, Input, Modal } from "antd";
import { getApiAccountInfoByPhone } from "../../_urls/accounts.api";
import { getGETOptions } from "../../_urls";
import { history } from "../../_helpers";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const cx = classNames.bind(styles);
const validateMessages = {
  required: "필수 입력창입니다.",
  types: {
    email: "이메일 양식을 확인해주세요.",
    phone: "전화번호 양식을 확인해주세요.",
    password: "비밀번호는 최대 6자 이상이어야 합니다.",
  },
};

class AccountReadPage extends Component {
  formRef = React.createRef();

  state = {
    checkedAccountExist: false,
    isLoading: false,
    alert_msg_title: "",
    modalVisible: false,
    redirect_url: "",
    alert_msg: "",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  fillHyphen = (e) => {
    let phone = e.target.value;
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
    }
    this.formRef.current.setFieldsValue({
      phone: phone,
    });
  };

  _changePhone = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value[0] === "+") {
      this.formRef.current.setFieldsValue({
        phone: e.target.value,
      });
    } else {
      return this.fillHyphen(e);
    }
  };

  _requestEdit = (values) => {
    let { phone } = values;
    let country_code = "+82";
    if (phone.includes("+")) {
      country_code = phone.split("-")[0];
      phone = phone.split("-").slice(1).join("");
    } else {
      phone = phone.split("-").join("");
    }
    if (phone.includes("01000000000")) {
      alert(
        "해당 연락처로 등록되어 있으신 고객꼐서는 관리자에게 문의 바랍니다."
      );
      return;
    }
    let endpoint = getApiAccountInfoByPhone(phone, country_code);
    let options = getGETOptions();
    this.setState({
      isLoading: true,
    });
    fetch(endpoint, options)
      .then((resp) => {
        if (resp.status !== 200) {
          this.setState({
            modalVisible: true,
            alert_msg_title: "잘못된 요청",
            alert_msg: `고객 정보가 존재하지 않습니다.\n올바른 연락처를 입력해주세요.`,
            isLoading: false,
          });
          return Promise.reject("No result");
        }
        return resp.json();
      })
      .then((result) => {
        if (result.ok) {
          this.setState({
            isLoading: false,
            modalVisible: true,
            alert_msg_title: "발송 완료",
            alert_msg: `가입하신 이메일을 해당 연락처로 발송했습니다.`,
          });
        }
      });
  };

  render() {
    const {
      isLoading,
      alert_msg_title,
      modalVisible,
      redirect_url,
      alert_msg,
    } = this.state;
    return (
      <div>
        <div className={cx("wrapper")}>
          <NavContainer />
          <div className={cx("pageContainer")}>
            <div className={cx("leftContainer")}>
              <div className={cx("contentWrapper")}>
                <h3>
                  휴대폰 번호를
                  <br />
                  입력해주세요.
                </h3>
                <div className={cx("formWrapper")}>
                  <Form
                    name="editAccountInfo"
                    onFinish={this._requestEdit}
                    onFinishFailed={this.onFinishFailed}
                    validateMessages={validateMessages}
                    ref={this.formRef}
                  >
                    <Form.Item
                      label="연락처"
                      name="phone"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(validateMessages.required);
                            }
                            if (
                              !value.match(
                                /^[0][1][0-9]{1}[-]+[0-9]{4}[-]+[0-9]{4}$/
                              ) &&
                              !value.match(/^[\+][0-9]{1,5}[-][0-9]{6,20}$/im)
                            ) {
                              return Promise.reject(
                                validateMessages.types.phone
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      onChange={this._changePhone}
                      getValueFromEvent={this._changePhone}
                    >
                      <Input
                      // onChange={e => this.fillHyphen(e)}
                      />
                    </Form.Item>
                    <span style={{ fontSize: 13 }}>
                      * 해외 고객은 국가 번호를 반드시 양식에 맞게 작성해주세요.
                      <br />
                      ex) +1-1231231234
                    </span>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        이메일 찾기
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className={cx("rightContainer")}></div>
          </div>
          {modalVisible && (
            <Modal
              title={alert_msg_title}
              visible={modalVisible}
              closable={true}
              footer={[
                <Button
                  key="ok"
                  type="primary"
                  onClick={() => {
                    this.setState({ modalVisible: false });
                    history.push("/login?next=mypage");
                  }}
                >
                  확인
                </Button>,
              ]}
            >
              <p>{alert_msg}</p>
            </Modal>
          )}
        </div>
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedAccountReadPage = connect(mapStateToProps)(AccountReadPage);
export { connectedAccountReadPage as AccountReadPage };
