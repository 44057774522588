import { PDFDownloadLink } from "@react-pdf/renderer";
import { useGetValidEnrollments } from "../../../../businesslogics/hackle/useFeatureFlag";
import { sendLog } from "../../../../businesslogics/logging";
import { useIsMobile } from "../../../../hooks/device";
import { sendLogCertificate, useReceipt } from "../../../../queries/mypage";
import { CompletionCertificate } from "../CertificateTemplates/CompletionTemplate";
import { EnrollmentCertificate } from "../CertificateTemplates/EnrollmentTemplate";
import { DownloadIcon, Receipt } from "../Icons/Icons";
import { CertificateCardMobile } from "./CertificateCardMobile";
import * as S from "./Certificates.style";

export const parsePeriod = (startDate, endDate) => {
  const parseAndReplace = (string) => {
    // ISO 문자열을 Date 객체로 변환 후 로컬 시간대의 날짜 문자열로 변환
    const date = new Date(string);
    return date
      .toLocaleDateString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replaceAll(". ", ".")
      .slice(0, -1);
  };

  const start = parseAndReplace(startDate);
  // 종료일에서 하루를 뺀 날짜 계산
  const endDateObj = new Date(endDate);
  endDateObj.setDate(endDateObj.getDate() - 1);
  const end = parseAndReplace(endDateObj.toISOString());

  return `${start} ~ ${end.split(".")[0] === "2099" ? "평생" : end}`;
};

export const isReceiptDownloadable = (receiptInfo) => {
  if (!receiptInfo) return false;
  return !!(receiptInfo.tid || receiptInfo.receiptUrl);
};

export const getReceipt = (enrolled) => {
  const tid = enrolled.tid;
  const receipt_url = enrolled.receipt_url;
  const isTidNotPossible = tid === "" || !tid;
  const isReceiptUrlNotPossible = receipt_url === "" || !receipt_url;
  if (isTidNotPossible && isReceiptUrlNotPossible) return;
  sendLog(
    "scc_mypagePage_click_certificate",
    {
      certificate_type: "영수증",
      course_title: enrolled.course_title,
      course_id: enrolled.course._id,
      round: enrolled.round_title,
      round_id: enrolled.enrolled.round_id,
      is_success: true,
    },
    "",
    true
  );
  if (receipt_url) {
    window.open(receipt_url);
  } else {
    window.open(
      `https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${tid}&type=0`,
      "_blank"
    );
  }
};

export const CertifiRow = ({ enrollment }) => {
  const { receiptInfo, handleReceiptClick } = useReceipt(enrollment);

  return (
    <S.CourseInfoRow>
      {enrollment.isCompleted ? (
        <S.CourseStatusTd>
          <S.CourseCompleted>수료</S.CourseCompleted>
        </S.CourseStatusTd>
      ) : (
        <S.CourseStatusTd>
          <S.CourseEnded>미수료</S.CourseEnded>
        </S.CourseStatusTd>
      )}

      <S.CourseTitleTd>
        <S.CourseTitleContainer>
          <S.CourseTitle>{`${enrollment.courseName}`}</S.CourseTitle>
          <S.CoursePeriod>
            {parsePeriod(enrollment.startDate, enrollment.endDate)}
          </S.CoursePeriod>
        </S.CourseTitleContainer>
      </S.CourseTitleTd>
      <S.ReceiptTd onClick={handleReceiptClick}>
        <S.DownloadContainer isActive={isReceiptDownloadable(receiptInfo)}>
          <S.IconWrapper>
            <Receipt />
          </S.IconWrapper>
        </S.DownloadContainer>
      </S.ReceiptTd>
      <S.ReceiptTd>
        <S.DownloadContainer
          isActive={enrollment.canIssueEnrollmentCertificate}
          onClick={() => sendLogCertificate("수강증", enrollment.systemType)}
        >
          {enrollment.canIssueEnrollmentCertificate ? (
            <PDFDownloadLink
              document={
                <EnrollmentCertificate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: enrollment.courseName,
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
              fileName={`수강증_${enrollment.courseName}.pdf`}
            >
              {({ loading }) => (
                <S.DownloadContainer isActive={!loading}>
                  {loading ? (
                    <S.SolvingImg
                      src={"/v2/assets/icons/loading_spinner.png"}
                    />
                  ) : (
                    <S.IconWrapper>
                      <DownloadIcon />
                    </S.IconWrapper>
                  )}
                </S.DownloadContainer>
              )}
            </PDFDownloadLink>
          ) : (
            <S.IconWrapper>
              <DownloadIcon />
            </S.IconWrapper>
          )}
        </S.DownloadContainer>
      </S.ReceiptTd>
      <S.CertifiTd>
        <S.DownloadIconContainer>
          <S.DownloadContainer
            isActive={enrollment.canIssueCompletionCertificate}
            onClick={() => sendLogCertificate("수료증", enrollment.systemType)}
          >
            {enrollment.canIssueCompletionCertificate ? (
              <PDFDownloadLink
                document={
                  <CompletionCertificate
                    enrollmentData={{
                      name: enrollment.name,
                      courseName: enrollment.courseName,
                      coursePeriod: parsePeriod(
                        enrollment.startDate,
                        enrollment.endDate
                      ),
                      isKdc: enrollment.isKdc,
                    }}
                  />
                }
                fileName={`수료증_${enrollment.courseName}.pdf`}
              >
                {({ loading }) => (
                  <S.DownloadContainer isActive={!loading}>
                    {loading ? (
                      <S.SolvingImg
                        src={"/v2/assets/icons/loading_spinner.png"}
                      />
                    ) : (
                      <S.IconWrapper>
                        <DownloadIcon />
                      </S.IconWrapper>
                    )}
                  </S.DownloadContainer>
                )}
              </PDFDownloadLink>
            ) : (
              <S.IconWrapper>
                <DownloadIcon />
              </S.IconWrapper>
            )}
          </S.DownloadContainer>
        </S.DownloadIconContainer>
      </S.CertifiTd>
    </S.CourseInfoRow>
  );
};

export const Certificates = () => {
  const { data: validEnrollments } = useGetValidEnrollments();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <>
          <S.CertifiDesktopWrapper>
            <table>
              <S.CertifiHeaderRows>
                <S.CertifiCaution colSpan={"2"}>
                  * 수강증/수료증 발급은 최대 15초 소요될 수 있습니다.
                  <br />* 수강증 : 수강 시작일 이후 / 수료증 : 수강 종료일
                  다음날부터 다운로드 가능합니다.
                </S.CertifiCaution>
                <S.CertifiHeader>영수증</S.CertifiHeader>
                <S.CertifiHeader>수강증</S.CertifiHeader>
                <S.CertifiHeader>수료증</S.CertifiHeader>
              </S.CertifiHeaderRows>
              {validEnrollments?.map((enrollment, idx) => {
                return (
                  <CertifiRow
                    key={"certifirow" + idx}
                    enrollment={enrollment}
                  />
                );
              })}
            </table>
          </S.CertifiDesktopWrapper>
        </>
      ) : (
        <>
          <S.CertificateMobileWrapper>
            <S.CertifiMaxwidthWrapper>
              <S.CertifiMobileTitle>수강증/수료증</S.CertifiMobileTitle>
              <S.CertifiMobileSubTitle>
                * 수강증 : 수강 시작일 이후 / 수료증 : 수강 종료일 다음날부터
                다운로드 가능합니다.
                <br />* 파일 다운로드는 최대 15초 소요될 수 있습니다.
              </S.CertifiMobileSubTitle>
              <S.CertifiCardContainer>
                {validEnrollments?.map((enrollment) => {
                  return <CertificateCardMobile enrollment={enrollment} />;
                })}
              </S.CertifiCardContainer>
            </S.CertifiMaxwidthWrapper>
          </S.CertificateMobileWrapper>
        </>
      )}
    </>
  );
};
