import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useUserMetaData, useUserV2CouponCountQuery } from "../../queries/gnb";
import { academiaFFAtom } from "../hackle/flag.store";

export const useCouponCountText = (userId) => {
  const [text, setText] = useState("");
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  const userCouponCount = useUserV2CouponCountQuery(userId);
  const userMetaData = useUserMetaData(userId);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isOn) {
      setText(getCouponCountText(userCouponCount.data));
    }
    if (!isOn) {
      setText(userMetaData.data?.coupon);
    }
  }, [isLoading, isOn, userCouponCount.data, userMetaData.data?.coupon]);

  return text;
};

const getCouponCountText = (count) => {
  if (count === undefined) {
    return "";
  }

  if (count > 0) {
    return `${count}장`;
  }
  return "없음";
};
