import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getApiResetPassword,
  getApiVerifyToken,
} from "../../_urls/accounts.api";
import { getGETAuthOptions, getPostAuthOptions } from "../../_urls";
import { Form, Input, Button, Modal } from "antd";
import classNames from "classnames/bind";
import styles from "./ResetPasswordPage.module.scss";
import { history } from "../../_helpers";
import { setCookie } from "../../v2/_helper";
import { FooterV2 } from "../../_components/Footer/FooterV2";

const validateMessages = {
  required: "필수 입력창입니다.",
  types: {
    password: "비밀번호는 최대 6자 이상이어야 합니다.",
  },
};

const cx = classNames.bind(styles);
const CONV_AT = "$";
const CONV_DOT = "!";
const conversionTable = {
  CONV_AT: "@",
  CONV_DOT: ".",
};

class ResetPasswordPage extends Component {
  state = {
    password: "",
    errorMsg: undefined,
    isLoading: false,
    alert_msg_title: "",
    modalVisible: false,
    redirect_url: "",
    alert_msg: "",
  };

  constructor(props) {
    super(props);
    let { email, token } = this.props.match.params;
    email = email
      .split(CONV_AT)
      .join(conversionTable.CONV_AT)
      .split(CONV_DOT)
      .join(conversionTable.CONV_DOT);
    token = token.split(CONV_DOT).join(conversionTable.CONV_DOT);
    setCookie("user_token", token, 1);
    this._verifyAuth();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  _verifyAuth = () => {
    let endpoint = getApiVerifyToken();
    let options = getGETAuthOptions();
    fetch(endpoint, options)
      .then((resp) => {
        return resp.json();
      })
      .then((result) => {
        if (result.msg === "Token has expired") {
          this.setState({
            modalVisible: true,
            alert_msg_title: "유효 기간 만료",
            alert_msg:
              "링크의 유효 기간이 만료되었습니다.\n비밀번호 변경을 원하실 경우 다시 요청하시기 바랍니다.",
            redirect_url: "/accounts/edit",
          });
        } else if (result.msg !== undefined || result.ok !== true) {
          this.setState({
            modalVisible: true,
            alert_msg_title: "잘못된 링크",
            alert_msg:
              "잘못된 링크입니다.\n비밀번호 변경을 원하실 경우 다시 요청하시기 바랍니다.",
            redirect_url: "/accounts/edit",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _resetPassword = () => {
    let data = {
      password: this.state.password,
    };
    let endpoint = getApiResetPassword();
    let options = getPostAuthOptions(data);

    this.setState({
      isLoading: true,
    });
    fetch(endpoint, options)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.ok) {
          alert(result.msg);
          window.location.href = "/login?next=classroom";
        } else {
          this.setState({
            isLoading: false,
          });
          alert(
            "잘못된 요청입니다.\n비밀번호를 변경하지 않았으며 지속될 경우 관리자에게 문의하세요."
          );
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const {
      errorMsg,
      isLoading,
      alert_msg_title,
      modalVisible,
      redirect_url,
      alert_msg,
    } = this.state;
    return (
      <div className={cx("wrapper")}>
        <div className={cx("pageContainer")}>
          <div className={cx("leftContainer")}>
            <div className={cx("contentWrapper")}>
              <h3>새로운 비밀번호를 입력해주세요</h3>
              <div className={cx("formWrapper")}>
                <Form
                  name="resetPassword"
                  onFinish={this._resetPassword}
                  onFinishFailed={this.onFinishFailed}
                  validateMessages={validateMessages}
                  ref={this.formRef}
                >
                  <Form.Item
                    label="비밀번호"
                    name="password"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(validateMessages.required);
                          }
                          if (value.length < 6) {
                            return Promise.reject(
                              validateMessages.types.password
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </Form.Item>

                  {errorMsg && <p className={cx("errorMsg")}>{errorMsg}</p>}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      비밀번호 재설정 및 로그인
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={alert_msg_title}
          visible={modalVisible}
          closable={false}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => history.push(redirect_url)}
            >
              확인
            </Button>,
          ]}
        >
          <p>{alert_msg}</p>
        </Modal>
        <FooterV2 />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
  };
}

const connectedResetPasswordPage = connect(mapStateToProps)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };
